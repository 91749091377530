import { ApplicationState } from '../../stores';
import { ErrorType } from '../../model/FetchError';
import { MateriaPrima } from '../../model/materia_prima';

//stores 
import * as SuppliesStore from '../../redux/Middleware/SupplyMiddleware';

//#region Props

interface IProps {
    SelectedSupplyId: number | undefined;
    OnSelectedSupplyChanged: (supply: MateriaPrima | undefined) => void;
}

interface ConnectedProps {
    // loading
    isLoading: boolean | undefined;
    LoadingSuccessfully: boolean | undefined;
    failOnLoading: boolean | undefined;
    error: ErrorType | undefined;
    supplies: MateriaPrima[] | undefined;
    selectedSupply: MateriaPrima | undefined;
}

interface MedicalServicesDispatchProps {
    GetAllSupplies: typeof SuppliesStore.actionCreators.GetAllSupplies;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: ApplicationState, ownProps: IProps) => ({
    // loading
    isLoading: state.Supplies?.isLoadingAll,
    LoadingSuccessfully: state.Supplies?.isLoadingAllSuccess,
    failOnLoading: state.Supplies?.FailGettingAll,
    error: state.Supplies?.Error,
    supplies: state.Supplies?.RawMaterials || [],
    selectedSupply: state.Supplies?.RawMaterials?.find(x => x.id === ownProps.SelectedSupplyId)
});

export const mapDispatchToProps = {
    ...SuppliesStore.actionCreators
};

//#endregion Props