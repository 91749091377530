import logo from '../../assets/LogoExpoColor.jpeg';
import './HeaderBarComponent.css';

export const HeaderBarComponent = () => {
  return (
    <div className="headerbar">
      <img src={logo} className="img" alt="Expo Color" />
    </div>
  );
};
