import { Action, Reducer } from 'redux';
import { SuppliesState } from '../States/SuppliesState';
import { MateriaPrima } from '../../model/materia_prima';

//Actions
import * as Actions from '../Actions/SuppliesActions';

//Functions
import * as Functions from '../Functions/Commons';

const unloadedState: SuppliesState = {
  isLoadingAll: false,
  isLoadingAllSuccess: undefined,
  isLoadingOne: false,
  isLoadingOneSuccess: undefined,
  isSaving: false,
  isSavingSuccess: undefined,
  isAdding: false,
  isAddingSuccess: undefined,
  isDeletingOne: false,
  isDeletingOneSuccess: undefined,
  FailDeletingOne: false,
  FailAdding: false,
  FailSaving: false,
  FailGettingAll: false,
  FailGettingOne: false,
  isFiltered: false,
  filterCriteria: undefined,
  Supplies: [],
  Containers: [],
  RawMaterials: [],
  SelectedSupply: undefined,
  Error: undefined,
  isLoadingLowStockSupplies: false,
  loadingLowStockSuppliesSuccess: undefined,
  failOnLoadingLowStockSupplies: false,
  lowStockSupplies: []
};

type KnownAction =
  | Actions.FailAddSupply
  | Actions.FailGetAllSupplies
  | Actions.FailGetSupply
  | Actions.FailSaveSupply
  | Actions.ReceiveAddedSupply
  | Actions.ReceiveAllSupplies
  | Actions.ReceiveSavedSupply
  | Actions.ReceiveSupply
  | Actions.RequestAddSupply
  | Actions.RequestAllSupplies
  | Actions.ReceiveSavedSupply
  | Actions.ReceiveSupply
  | Actions.RequestAddSupply
  | Actions.RequestAllSupplies
  | Actions.RequestSaveSupply
  | Actions.RequestSupply
  | Actions.SetSelectedSupply
  | Actions.UnsetSelectedSupply
  | Actions.SetSupplyNewStockAmount
  | Actions.RequestAllLowStockSupplies
  | Actions.ReceiveAllLowStockSupplies
  | Actions.FailGetAllLowStockSupplies
  | Actions.RequestDeleteSupply
  | Actions.ReceiveDeletedSupply
  | Actions.FailDeleteSupply;

export const reducer: Reducer<SuppliesState> = (
  state: SuppliesState | undefined,
  incomingAction: Action
): SuppliesState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case 'REQUEST_ALL_SUPPLIES':
      return {
        ...state,
        isLoadingAll: true,
        isLoadingAllSuccess: undefined,
        FailGettingAll: false,
        Error: undefined
      };
    case 'RECEIVE_ALL_SUPPLIES':
      return {
        ...state,
        isLoadingAll: false,
        isLoadingAllSuccess: true,
        FailGettingAll: false,
        Error: undefined,
        Supplies: action.supplies,
        RawMaterials: action.supplies.filter(
          (supply: MateriaPrima) => !supply.esEnvase
        ),
        Containers: action.supplies.filter(
          (supply: MateriaPrima) => supply.esEnvase
        ),
        filterCriteria: action.filterCriteria,
        isFiltered: action.isFiltered
      };
    case 'FAIL_GET_ALL_SUPPLIES':
      return {
        ...state,
        isLoadingAll: false,
        isLoadingAllSuccess: false,
        FailGettingAll: true,
        Error: action.Error,
        Supplies: [],
        Containers: [],
        RawMaterials: [],
        filterCriteria: '',
        isFiltered: false
      };
    case 'REQUEST_SUPPLY':
      return {
        ...state,
        isLoadingOne: true,
        isLoadingOneSuccess: undefined,
        FailGettingOne: false,
        Error: undefined
      };
    case 'RECEIVE_SUPPLY':
      return {
        ...state,
        isLoadingOne: false,
        isLoadingOneSuccess: true,
        FailGettingOne: false,
        Error: undefined,
        SelectedSupply: action.supply
      };
    case 'FAIL_GET_SUPPLY':
      return {
        ...state,
        isLoadingOne: false,
        isLoadingOneSuccess: false,
        FailGettingOne: true,
        Error: action.Error,
        SelectedSupply: undefined
      };
    case 'REQUEST_ALL_LOW_STOCK_SUPPLIES':
      return {
        ...state,
        isLoadingLowStockSupplies: true,
        loadingLowStockSuppliesSuccess: undefined,
        failOnLoadingLowStockSupplies: false,
        Error: undefined
      };
    case 'RECEIVE_ALL_LOW_STOCK_SUPPLIES':
      return {
        ...state,
        isLoadingLowStockSupplies: false,
        loadingLowStockSuppliesSuccess: true,
        failOnLoadingLowStockSupplies: false,
        Error: undefined,
        lowStockSupplies: action.supplies
      };
    case 'FAIL_GET_ALL_LOW_STOCK_SUPPLIES':
      return {
        ...state,
        isLoadingLowStockSupplies: false,
        loadingLowStockSuppliesSuccess: false,
        failOnLoadingLowStockSupplies: true,
        Error: action.Error
      };
    case 'REQUEST_ADD_SUPPLY':
      return {
        ...state,
        isAdding: true,
        isAddingSuccess: undefined,
        FailAdding: false,
        Error: undefined
      };
    case 'RECEIVE_ADDED_SUPPLY':
      return {
        ...state,
        isAdding: false,
        isAddingSuccess: true,
        FailAdding: false,
        Error: undefined,
        SelectedSupply: action.newSupply,
        Supplies: [...state.Supplies, action.newSupply]
      };
    case 'FAIL_ADD_SUPPLY':
      return {
        ...state,
        isAdding: false,
        isAddingSuccess: false,
        FailAdding: true,
        Error: action.Error
      };
    case 'REQUEST_DELETE_SUPPLY':
      return {
        ...state,
        isDeletingOne: true,
        isDeletingOneSuccess: undefined,
        FailDeletingOne: false,
        Error: undefined
      };
    case 'FAIL_DELETE_SUPPLY':
      return {
        ...state,
        isDeletingOne: false,
        isDeletingOneSuccess: false,
        FailDeletingOne: true,
        Error: action.Error
      };

    case 'RECEIVE_DELETED_SUPPLY':
      return {
        ...state,
        isDeletingOne: false,
        isDeletingOneSuccess: true,
        FailDeletingOne: false,
        Error: undefined,
        SelectedSupply: undefined,
        Supplies: state.Supplies.filter(
          (supply: MateriaPrima) => supply.id !== action.SupplyId
        )
      };
    case 'SET_SELECTED_SUPPLY':
      return {
        ...state,
        SelectedSupply: action.selectedSupply
      };
    case 'REQUEST_SAVE_SUPPLY':
      return {
        ...state,
        isSaving: true,
        isSavingSuccess: undefined,
        FailSaving: false,
        Error: undefined
      };
    case 'RECEIVE_SAVED_SUPPLY':
      return {
        ...state,
        isSaving: false,
        isSavingSuccess: true,
        FailSaving: false,
        Error: undefined,
        SelectedSupply: action.savedSupply,
        Supplies: [
          ...state.Supplies.filter(
            (supply: MateriaPrima) => supply.id !== action.savedSupply.id
          ),
          action.savedSupply
        ].sort(Functions.DynamicSort('codigo'))
      };
    case 'FAIL_SAVE_SUPPLY':
      return {
        ...state,
        isSaving: false,
        isSavingSuccess: false,
        FailSaving: true,
        Error: action.Error,
        SelectedSupply: undefined
      };
    case 'SET_SUPPLY_NEW_STOCK_AMOUNT': {
      let auxSupply = state.SelectedSupply;
      if (auxSupply) auxSupply.stock!.cantidad = action.NewAmount;
      return {
        ...state,
        SelectedSupply: auxSupply ? auxSupply : undefined,
        Supplies: auxSupply
          ? [
              ...state.Supplies.filter(
                (supply: MateriaPrima) => supply.id !== action.SupplyId
              ),
              auxSupply
            ]
          : state.Supplies
      };
    }
  }

  return state;
};
