import { Orden } from '../../model/orden';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import Moment from 'react-moment';
import { NavLink } from 'react-router-dom';

export interface IProps {
  Ordenes: Orden[];
}

export const OrdenFabricacionTableList = (props: IProps) => {
  return (
    <div style={{width: 'calc(100vw - 300px)'}}>
     <div style={{width: 'calc(100vw - 300px)'}}>
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Lote</th>
            <th>Codigo</th>
            <th>Cliente</th>
            <th>Fecha</th>
            <th>Producto</th>
            <th>Cantidad</th>
            <th>Operario</th>
            <th>Verificación</th>
          </tr>
        </thead>
        <tbody>
          {props.Ordenes.map((orden: Orden) => (
            <tr>
              <td>
                <NavLink to={'ordenes/info/' + orden.id}>{orden.lote}</NavLink>
              </td>
              <td>
                <strong>{orden.producto?.codigo}</strong>
              </td>
              <td>{orden.codigoCliente}</td>
              <td>
                {moment(orden.fechaorden).add(-3, 'h').format('DD/MM/YYYY HH:mm')}
              </td>
              <td>{orden.producto?.nombre}</td>
              <td style={{ textAlign: 'left' }}>{orden.cantidad} Kg</td>
              <td>
                {orden.operario
                  ? orden.operario.apellido + ', ' + orden.operario.nombre
                  : ''}
              </td>
              <td>
                {orden.cancelado ?
                  <span className="badge text-bg-secondary">Anulada</span>
                 : orden.aprobado ? (
                  <span className="badge bg-success">Aprobada</span>
                ) : (
                  <span className="badge bg-danger">Desaprobada</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      <div>
      <h6>
      {props.Ordenes.length === 0 && <label>No hay ordenes finalizadas</label>}
      </h6>
      </div>
    </div>
  );
};
