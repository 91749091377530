import { Producto } from '../../../model/producto';

export interface IProps {
  producto: Producto;
}

export const ProductHeader = (props: IProps) => {
  return (
    <div className="EntityHeader">
      <h1>{props.producto.codigo}</h1>
      <h2>{props.producto.nombre}</h2>
      <p>{props.producto.descripcion}</p>
    </div>
  );
};

export default ProductHeader;
