import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { User } from '../../../model/authenticate';

import { ApplicationState } from '../../../stores';

// stores
import * as AuthStore from '../../../stores/authentication';

export interface IProps {
  CurrentUser: User;
  onChangePassword?: (user: User) => void;
  onCancel?: () => void;
}

export const UserChangePasswordFormComponent = (props: IProps) => {
  const dispatch = useDispatch();

  const [submitted, setSubmitted] = useState<boolean>(false);

  // form fields
  const [id, setId] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [PasswordVerification, setPasswordVerification] =
    useState<boolean>(false);

  const [formValidationError, setFormValidationError] = useState<string[]>([]);

  // operator state
  const AuthState = useSelector(
    (state: ApplicationState) => state.authentication
  );

  useEffect(() => {
    if (props.CurrentUser) {
      setId(props.CurrentUser.id);
    }
  }, []);

  useEffect(() => {
    if (submitted && AuthState && AuthState.ChangedPasswordSuccess) {
      if (props.onChangePassword) props.onChangePassword(props.CurrentUser);
    }
  }, [AuthState?.ChangedPasswordSuccess, submitted]);

  // form validation
  const FormValidation = (): boolean => {
    let validationResult: boolean = true;
    let Errors: string[] = [];

    if (!password) {
      Errors = [...Errors, 'Debe informar la contraseña del usuario'];
      validationResult = false;
    }

    if (!confirmPassword) {
      Errors = [...Errors, 'confirme la contraseña del usuario'];
      validationResult = false;
    }

    if (password !== confirmPassword) {
      Errors = [...Errors, 'las cotraseñas no coinciden'];
      validationResult = false;
    } else {
      setPasswordVerification(true);
    }

    setFormValidationError(Errors);

    return validationResult;
  };

  // events
  const onCancelFormHandler = () => {
    if (props.onCancel) props.onCancel();
  };

  const onSubmitHandler = () => {
    setSubmitted(true);
    if (FormValidation()) {
      dispatch(
        mapDispatchToProps.ChangePassword(props.CurrentUser!, password!)
      );
    }
  };

  return (
    <>
      <div className="contentpage">
        <div>
          <form className="form">
            <small>Usuario</small>
            <h6>
              {props.CurrentUser?.firstName +
                ', ' +
                props.CurrentUser?.lastName}
            </h6>
            <div className="formContent">
              {(AuthState?.FailAdding || AuthState?.FailSaving) &&
                AuthState?.error && (
                  <div className="ErrorContainer">
                    <label>{AuthState?.error?.ErrorMessage}</label>
                    {AuthState?.error?.Errors && (
                      <ul>
                        {AuthState?.error?.Errors.map((error: string) => (
                          <li>{error}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              {formValidationError.length > 0 && (
                <div className="ErrorContainer">
                  <label>Atención</label>
                  <ul>
                    {formValidationError.map((error: string) => (
                      <li>{error}</li>
                    ))}
                  </ul>
                </div>
              )}

              <label className="form-label">
                Contraseña <span className="text-danger">*</span>
              </label>
              <input
                type="password"
                title="Código de materia prima"
                className="form-control"
                maxLength={100}
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
              />
              {submitted && !password && (
                <small className="text-danger">Dato requerido</small>
              )}
              <label className="form-label">
                Confirmación de Contraseña{' '}
                <span className="text-danger">*</span>
              </label>
              <input
                type="password"
                title="Correo electrónico"
                className="form-control"
                maxLength={150}
                value={confirmPassword}
                onChange={(e: any) => setConfirmPassword(e.target.value)}
              />
              {submitted && !confirmPassword && (
                <small className="text-danger">Dato requerido</small>
              )}
              {submitted && !PasswordVerification && (
                <small className="text-danger">
                  Las contraseñas no coinciden
                </small>
              )}
            </div>
            <div className="formButtons">
              {AuthState && !AuthState.isAdding && !AuthState?.isSaving ? (
                <>
                  <button
                    type="button"
                    onClick={onSubmitHandler}
                    className="btn btn-primary"
                  >
                    Confirmar
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={onCancelFormHandler}
                  >
                    Cancelar
                  </button>
                </>
              ) : (
                <>
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Cargando...</span>
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.Operators
});

const mapDispatchToProps = {
  ...AuthStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserChangePasswordFormComponent as any);
