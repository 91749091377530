import { Redirect, Route } from 'react-router';
import Layout from './components/Layout';
import { HomePage } from './pages/home';
import { EditProductoPage } from './pages/editProducto';
import { MateriasPrimasPage } from './pages/MateriasPrimas';
import { FormulaPage } from './pages/formula';
import { FormulaDetallePage } from './pages/formula_detalle';
import './App.css';
import { OrdenesFabricacionPage } from './pages/ordenesFabricacion';
import { NewOrdenFabricacionPage } from './pages/newOrdenFabricacion';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import { ProductosPage } from './pages/Productos';
import { ProductoPage } from './pages/producto';
import { OrdenFabricacionPage } from './pages/ordenFabricacion';
import { MateriaPrimaPage } from './pages/materiaprima';
import { ProductoInfoPage } from './pages/ProductoInfo';
import { SettingsPage } from './pages/Settings';
import { RemitosPage } from './pages/Remitos';

import { useAuth } from "react-oidc-context";

export const App = () => {

  const auth = useAuth();

  // useEffect(() => {
  //   // the `return` is important - addAccessTokenExpiring() returns a cleanup function
  //   return auth.events.addAccessTokenExpiring(() => {
  //     alert('pasa por aca')
  //     auth.signinSilent();

  //   })
  // }, [auth.events, auth.signinSilent]);

  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    return (
      <Layout>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <AuthorizeRoute exact path="/home" component={HomePage} />
        <AuthorizeRoute exact path="/productos" component={ProductosPage} />
        <AuthorizeRoute exact path="/ordenes" component={OrdenesFabricacionPage} />
        <AuthorizeRoute
          exact
          path="/ordenes/info/:id"
          component={OrdenFabricacionPage}
        />
        <AuthorizeRoute
          exact
          path="/ordenes/nuevo"
          component={NewOrdenFabricacionPage}
        />
        <AuthorizeRoute exact path="/productos/:id" component={ProductoPage} />
        <AuthorizeRoute
          exact
          path="/productos/info/:id"
          component={ProductoInfoPage}
        />
        <AuthorizeRoute
          exact
          path="/productos/edit/:id"
          component={EditProductoPage}
        />
        <AuthorizeRoute exact path="/supplies" component={MateriasPrimasPage} />
        <AuthorizeRoute exact path="/supplies/:id" component={MateriaPrimaPage} />
        <AuthorizeRoute exact path="/formula" component={FormulaPage} />
        <AuthorizeRoute
          exact
          path="/formula/detalle/:id"
          component={FormulaDetallePage}
        />
        <AuthorizeRoute exact path="/remitos" component={RemitosPage} />
        <AuthorizeRoute exact path="/settings" component={SettingsPage} />
      </Layout>
    );
  } else {
    auth.signinRedirect();
    return <></>
  }
};

export default App;
