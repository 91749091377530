import { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ApplicationState } from '../stores';
import ProductHeader from '../components/producto/Header/ProductHeader';

// stores
import * as ProductosStore from '../stores/Productos';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import ProductCostComponent from '../components/producto/ProductoCosto/ProductCostComponent';
import ReplacementCostComponent from '../components/producto/ReplacementCost/ReplacementCostComponent';

export const ProductoInfoPage = () => {
  const { id }: { id: string } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const ProductosState = useSelector(
    (state: ApplicationState) => state.productos
  );

  useEffect(() => {
    dispatch(mapDispatchToProps.getProducto(Number(id)));
  }, []);

  return ProductosState?.producto ? (
    <>
      <div className="pageHeader">
        <ProductHeader producto={ProductosState?.producto!}></ProductHeader>
        <button
          type="button"
          title="Volver"
          className="btn btn-primary mt-3 mb-2 me-3 float-end"
          onClick={() => {
            history.goBack();
          }}
        >
          <FaArrowAltCircleLeft />
        </button>
      </div>
      <div className="orderDetailsContainer">
        <div></div>
        <div>
          <ReplacementCostComponent productId={Number(id)} />
        </div>
        <div>
          <ProductCostComponent productId={Number(id)} />
        </div>
      </div>
    </>
  ) : (
    <div className="loadingFlag">Cargando...</div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.productos,
  ...state.formulas
});

const mapDispatchToProps = {
  ...ProductosStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductoInfoPage as any);
