import './UserComponent.css';
import { FaUser } from 'react-icons/fa';

// Stores
import * as AuthStore from '../../stores/authentication';
import { ApplicationState } from '../../stores';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useAuth } from 'react-oidc-context';

export const UserComponent = () => {

  const auth = useAuth();

  const dispatch = useDispatch();
  const history = useHistory();

  // events
  const onCloseSessionHandler = () => {
    auth.removeUser();
    auth.signoutRedirect();
  };

  const onLoginHandler = () => {};

  useEffect(() => {
    if (auth.isAuthenticated && !auth.isLoading) {
      const returnUrl = window.location.toString();
      //history.push('login/?returnUrl=' + returnUrl);
    }
  }, [auth.isAuthenticated]);

  const UserAuthenticated = (
    <div className="UserContent">
      <div className="UserIcon">
        <FaUser />
      </div>
      <label>
        {auth.user?.profile.given_name + ' ' + auth.user?.profile.family_name}
      </label>
      <button className="btn btn-primary" onClick={onCloseSessionHandler}>
        Cerrar Sesión
      </button>
    </div>
  );

  const UserUnAuthenticated = (
    <div className="UserContent">
      {/* <button className="btn btn-info">Iniciar sesión</button> */}
    </div>
  );

  return auth.isAuthenticated ? UserAuthenticated : UserUnAuthenticated;
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.authentication
});

const mapDispatchToProps = {
  ...AuthStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserComponent as any);
