import { IResult } from "../model/Result";

// Functions
import * as Functions from '../redux/Functions/Commons';

const BaseURL = process.env.REACT_APP_API_ENDPOINT + 'v1/ordenesfabricaciones/';


export const CancelOrder = async (
    OrderId: number,
    token: String
  ): Promise<any> => {
  
    let RequestURL: string = BaseURL + OrderId + '/cancel' ;
    
    try {
      const Response: any = await fetch(RequestURL, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        method: 'PUT'
      })
        .then((response) => {
          if (!response.ok) {
            throw response;
          } else {
            return response.json() as Promise<
              IResult<any>
            >;
          }
        })
        .then((data: IResult<any>) => {
          return OrderId;
        })
        .catch((error: any) => {
          if (error.text)
            return error.text().then((body: any) => {
              throw Functions.HttpErrorHandler(body, error);
            });
          else throw Functions.HttpErrorHandler(null, error);
        });
  
      return Response;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  };