import { useEffect } from 'react';
import { PasosForm } from '../components/formula/pasosForm';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../stores';
import * as FormulaStore from '../stores/Formula';
import * as MateriaPrimaStore from '../stores/Materia_Prima';
import { useHistory, useParams } from 'react-router-dom';
export const FormulaDetallePage = () => {
  const { id }: { id: string } = useParams();

  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    if (id !== '-1') {
      dispatch(mapDispatchToProps.getDetalleFormula(id));
    }
  }, []);
  const RouteChange = () => {
    history.push('/formula');
  };
  return (
    <div>
      <h1>Detalle</h1>
      <div className="row col-md-12">
        <div className="col-md-12 float-right">
          <button
            type="button"
            className="btn btn-primary mb-2 float-right"
            onClick={RouteChange}
          >
            Volver
          </button>
        </div>
        <PasosForm FormulaId={Number(id)} />
      </div>
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  ...state.formulas
});

const mapDispatchToProps = {
  ...FormulaStore.actionCreators,
  ...MateriaPrimaStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormulaDetallePage as any);
