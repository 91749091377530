import { Action, Reducer } from 'redux';
import { Formula, NewFormula } from '../model/formula';
import { ListaFormula } from '../model/lista_formula';
import { AppThunkAction } from './';
import { Pasos } from '../model/pasos';
import { ErrorType } from '../model/FetchError';

// functions
import * as Functions from '../redux/Functions/Commons';
import { IResult } from '../model/Result';

interface getAllFormulas {
  type: 'GET_ALL_FORMULAS';
  formulas: ListaFormula[];
}
/*interface getAllProductos {
  type: "GET_ALL_PRODUCTOS";
  productos: Producto[];
}
interface getAllMateriaPrima {
  type: "GET_ALL_MATERIA_PRIMAS";
  materiasPrimas: MateriaPrima[];
}*/

// Formula

interface RequestAddFormula {
  type: 'REQUEST_ADD_FORMULA';
  newFormula: NewFormula;
}

interface ReceiveAddedFormula {
  type: 'RECEIVE_ADDED_FORMULA';
  formula: ListaFormula;
}

interface FailAddFormula {
  type: 'FAIL_ADD_FORMULA';
  Error: ErrorType;
}
interface getFormula {
  type: 'SET_FORMULA';
  formula: ListaFormula;
}
interface addFormula {
  type: 'ADD_FORMULA';
  formula: ListaFormula;
}
interface getPasos {
  type: 'SET_PASOS';
  pasos?: Pasos[];
}

interface updatePasos {
  type: 'UPDATE_PASOS';
  paso: Pasos;
}
interface updatePasosOrden {
  type: 'UPDATE_PASOS_ORDEN';
  paso: Pasos;
}
interface updateOrden {
  type: 'UPDATE_ORDEN';
  lista: any;
}
interface addPaso {
  type: 'ADD_PASO';
  paso: Pasos;
}
interface deletePaso {
  type: 'DELETE_PASO';
  paso: Pasos;
}

interface deletePasoOrden {
  type: 'DELETE_PASO_ORDEN';
  position: number;
}
interface setPaso {
  type: 'SET_PASO';
  paso?: Pasos;
}

type KnownAction =
  | RequestAddFormula
  | ReceiveAddedFormula
  | FailAddFormula
  | getAllFormulas
  // | getAllProductos
  | getFormula
  | addFormula
  // | getAllMateriaPrima
  | getPasos
  | updatePasos
  | updateOrden
  | addPaso
  | setPaso
  | deletePaso
  | deletePasoOrden
  | updatePasosOrden;

const FormulaBaseDir = process.env.REACT_APP_API_ENDPOINT + 'v1/formulas';

export const actionCreators = {
  getAllFormulas:
    (pageNumber?: number, pageSize?: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      if (pageNumber && pageSize) {
        await fetch(
          process.env.REACT_APP_API_ENDPOINT +
            'v1/formulas?PageNumber=0&PageSize=10'
        )
          .then((response) => response.json() as Promise<any>)
          .then((data) => {
            dispatch({
              type: 'GET_ALL_FORMULAS',
              formulas: data.data.sort(Functions.DynamicSort('nombre'))
            });
          });
      } else {
        await fetch(process.env.REACT_APP_API_ENDPOINT + 'v1/formulas')
          .then((response) => response.json() as Promise<any>)
          .then((data) => {
            dispatch({
              type: 'GET_ALL_FORMULAS',
              formulas: data.data.sort(Functions.DynamicSort('nombre'))
            });
          });
      }
    },
  getDetalleFormula:
    (id: string): AppThunkAction<getFormula> =>
    async (dispatch, getState) => {
      const appState = getState();
      await fetch(process.env.REACT_APP_API_ENDPOINT + 'v1/formulas/' + id)
        .then((response) => response.json() as Promise<any>)
        .then((data) => {
          dispatch({ type: 'SET_FORMULA', formula: data.data });
        });
    },
  getPasosFormula:
    (id: string): AppThunkAction<getPasos> =>
    async (dispatch, getState) => {
      const appState = getState();
      await fetch(
        process.env.REACT_APP_API_ENDPOINT + 'v1/formulas/' + id + '/pasos'
      )
        .then((response) => response.json() as Promise<any>)
        .then((data) => {
          dispatch({ type: 'SET_PASOS', pasos: data.data });
        });
    },
  setPasosFormula:
    (pasos: Pasos[]): AppThunkAction<getPasos> =>
    async (dispatch, getState) => {
      dispatch({
        type: 'SET_PASOS',
        pasos: pasos.sort(Functions.DynamicSort('orden'))
      });
    },
  updateOrden:
    (formulaId: number, orden: any[]): AppThunkAction<updatePasos> =>
    async (dispatch, getState) => {
      await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          'v1/formulas/' +
          formulaId +
          '/pasos',
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'PUT',
          body: JSON.stringify(orden)
        }
      )
        .then((response) => response.json() as Promise<any>)
        .then((data) => {
          console.log(data.data);
        });
    },
  addPasosFormula:
    (paso: Pasos): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      let pa: any;
      console.log(paso);
      if (paso.pausa) {
        pa = {
          orden: paso.orden,
          pausa: paso.pausa,
          observacion: paso.observacion,
          formulaid: paso.formulaid,
          minutospausa: paso.minutospausa,
          materiaprimaid: null
        };
      } else {
        pa = {
          orden: paso.orden,
          materiaprimaid: paso.materiaprima ? paso.materiaprima.id : null,
          valor: paso.valor,
          pausa: paso.pausa,
          observacion: paso.observacion,
          formulaid: paso.formulaid,
          minutospausa: null
        };
      }

      if (paso.id == -1) {
        await fetch(
          process.env.REACT_APP_API_ENDPOINT +
            'v1/formulas/' +
            paso.formulaid +
            '/pasos',
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(pa)
          }
        )
          .then((response) => response.json() as Promise<any>)
          .then((data) => {
            console.log(data.data);
            dispatch({ type: 'ADD_PASO', paso: data.data });
            dispatch({
              type: 'SET_PASO',
              paso: {
                id: -1,
                formulaid: paso.formulaid,
                materiaprimaid: paso.materiaprimaid,
                valor: 0,
                pausa: false,
                minutospausa: 0,
                observacion: ''
              }
            });
          });
      } else {
        await fetch(
          process.env.REACT_APP_API_ENDPOINT +
            'v1/formulas/' +
            paso.formulaid +
            '/pasos/' +
            paso.id,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify(pa)
          }
        )
          .then((response) => response.json() as Promise<any>)
          .then((data) => {
            dispatch({ type: 'UPDATE_PASOS', paso: paso });
            dispatch({
              type: 'SET_PASO',
              paso: {
                id: -1,
                formulaid: paso.formulaid,
                materiaprimaid: 0,
                valor: 0,
                pausa: false,
                minutospausa: 0,
                observacion: ''
              }
            });
          });
      }
    },
  deletePasosFormula:
    (paso: Pasos, idFormula: number): AppThunkAction<deletePaso> =>
    async (dispatch, getState) => {
      await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          'v1/formulas/' +
          idFormula +
          '/pasos/' +
          paso.id,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'DELETE'
        }
      )
        .then((response) => response.json() as Promise<any>)
        .then((data) => {
          dispatch({ type: 'DELETE_PASO', paso: paso });
        });
    },
  saveFormula:
    (formula: Formula): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      if (formula.id === -1) {
        await fetch(process.env.REACT_APP_API_ENDPOINT + 'v1/formulas', {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify(formula)
        })
          .then((response) => response.json() as Promise<any>)
          .then((data) => {});
      } else {
        await fetch(
          process.env.REACT_APP_API_ENDPOINT + 'v1/formulas/' + formula.id,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify(formula)
          }
        )
          .then((response) => {
            if (response.status >= 400) {
              throw response;
            } else {
              return response.json() as Promise<IResult<ListaFormula>>;
            }
          })
          .then((data: IResult<ListaFormula>) => {
            dispatch({ type: 'RECEIVE_ADDED_FORMULA', formula: data.data });
          })
          .catch((error: any) =>
            error.text().then((body: any) => {
              dispatch({
                type: 'FAIL_ADD_FORMULA',
                Error: Functions.HttpErrorHandler(body, error)
              });
            })
          );
      }
    },
  updatePasoOrden:
    (paso: Pasos): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      console.log(paso);
      dispatch({ type: 'UPDATE_PASOS_ORDEN', paso: paso });
      dispatch({
        type: 'SET_PASO',
        paso: {
          id: -1,
          formulaid: paso.formulaid,
          materiaprimaid: paso.materiaprimaid,
          valor: 0,
          pausa: false,
          minutospausa: 0,
          observacion: ''
        }
      });
    },
  AddFormulaToProduct:
    (newFormula: NewFormula): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      dispatch({ type: 'REQUEST_ADD_FORMULA', newFormula: newFormula });

      await fetch(FormulaBaseDir, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(newFormula)
      })
        .then((response) => {
          if (!response.ok) {
            throw response;
          } else {
            return response.json() as Promise<IResult<ListaFormula>>;
          }
        })
        .then((data: IResult<ListaFormula>) => {
          dispatch({ type: 'RECEIVE_ADDED_FORMULA', formula: data.data });
        })
        .catch((error: any) =>
          error.text().then((body: any) => {
            dispatch({
              type: 'FAIL_ADD_FORMULA',
              Error: Functions.HttpErrorHandler(body, error)
            });
          })
        );
    }
};

export interface FormulaState {
  isLoading: boolean;
  isAddingFormula: boolean;
  isAddingFormulaSuccess: boolean | undefined;
  failAddingFormula: boolean;
  formulas: ListaFormula[];
  formula?: ListaFormula;
  pasos?: Pasos[];
  paso?: Pasos;
  Error: ErrorType | undefined;
}

const unloadedState: FormulaState = {
  isLoading: false,
  pasos: [],
  formulas: [],
  isAddingFormula: false,
  isAddingFormulaSuccess: undefined,
  failAddingFormula: false,
  Error: undefined
};

export const reducer: Reducer<FormulaState> = (
  state: FormulaState | undefined,
  incomingAction: Action
): FormulaState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'GET_ALL_FORMULAS':
      return {
        ...state,
        formulas: action.formulas
      };
    case 'SET_FORMULA':
      return {
        ...state,
        formula: action.formula
      };
    case 'ADD_FORMULA':
      return {
        ...state,
        formulas: state.formulas
          .concat(action.formula)
          .sort(Functions.DynamicSort('nombre')),
        formula: state.formula,
        pasos: state.pasos,
        isLoading: false
      };
    case 'SET_PASOS':
      return {
        ...state,
        formula: state.formula,
        pasos: action.pasos,
        isLoading: false
      };
    case 'SET_PASO':
      return {
        ...state,
        paso: action.paso
      };
    case 'ADD_PASO':
      return {
        ...state,
        pasos: state.pasos?.concat(action.paso)
      };
    case 'DELETE_PASO':
      return {
        ...state,
        pasos: state.pasos?.filter((p) => p.id !== action.paso.id)
      };
    case 'UPDATE_PASOS':
      const index = state.pasos?.findIndex((p) => p.id === action.paso.id);
      const newArray = state.pasos ? [...state.pasos] : [];
      newArray[index || 0] = action.paso; //changing value in the new array
      return {
        ...state, //copying the orignal state
        pasos: newArray //reassingning todos to new array
      };
    case 'DELETE_PASO_ORDEN':
      if (action.position > -1 && state.pasos) {
        const newArray = [...state.pasos];

        const removed = newArray.splice(action.position, 1);
        return {
          ...state,
          pasos: newArray
        };
      }
      return state;
    case 'UPDATE_PASOS_ORDEN':
      console.log(state.pasos);
      console.log(action.paso.position);

      console.log(state.pasos && action.paso.position);
      if (state.pasos && action.paso.position !== undefined) {
        console.log('newArray');
        const newArray = [...state.pasos];
        console.log(newArray);
        newArray[action.paso.position] = action.paso;
        //changing value in the new array
        return {
          ...state, //copying the orignal state
          pasos: newArray //reassingning todos to new array
        };
      }
      return state;
    case 'REQUEST_ADD_FORMULA':
      return {
        ...state,
        isAddingFormula: true,
        isAddingFormulaSuccess: undefined,
        failAddingFormula: false
      };
    case 'RECEIVE_ADDED_FORMULA':
      return {
        ...state,
        isAddingFormula: false,
        isAddingFormulaSuccess: true,
        failAddingFormula: false,
        formula: action.formula
      };
    case 'FAIL_ADD_FORMULA':
      return {
        ...state,
        isAddingFormula: false,
        isAddingFormulaSuccess: false,
        failAddingFormula: true,
        Error: action.Error
      };
  }

  return state;
};
