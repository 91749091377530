import * as React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../stores';
import * as ProductosStore from '../../stores/Productos';
import * as OrdenFabricacionStore from '../../stores/OrdenFabricacion';
import { PasosFormOrden } from '../formula/pasosFormOrden';
import { SaveOrdenFull } from './saveOrdenFull';
import { PasosLite } from '../../model/pasosLite';

import '../../custom.css';
import './orden.css';
import { MateriaPrima } from '../../model/materia_prima';

export interface IProps {
  materiaPrimas: MateriaPrima[] | undefined;
  onAddedOrder: () => void;
}

export const ProductoFormOrden = (props: IProps) => {
  const dispatch = useDispatch();
  const [error, setError] = React.useState<string[]>([]);
  const [showForm, setShowForm] = React.useState(true);
  const [ComponentsErrors, setComponentsErrors] = React.useState<string[]>([]);
  const FormulaState = useSelector(
    (state: ApplicationState) => state.formulas?.pasos
  );
  const ProductosState = useSelector(
    (state: ApplicationState) => state.productos
  );

  // datos de producto
  const [CodigoProducto, setCodigoProducto] = React.useState<string>();
  const [NombreProducto, setNombreProducto] = React.useState<string>();
  const [Cantidad, setCantidad] = React.useState<number>();
  const [ComponentesCantidad, setComponentesCantidad] =
    React.useState<number>(0);
  
  const [formSubmitted, setFormsubmitted] = React.useState<boolean>(false);
  const [isSaving, setisSaving] = React.useState<boolean>(false);

  const OnHandleErrorFormulaComponents = (Errors: string[]) => {
    setComponentsErrors(Errors);
  };

  const CodigoProductoTextFieldChangeEvent = (e: any) => {
    setCodigoProducto(e.target.value);
  };

  const NombreProductoTextFieldChangeEvent = (e: any) => {
    setNombreProducto(e.target.value);
  };

  const CantidadTextFieldChangeEvent = (e: any) => {
    setCantidad(Number(e.target.value));
  };

  const onSave = () => {
    setFormsubmitted(true);
    var er: Array<string> = new Array<string>();

    if (!FormulaState || FormulaState.length <= 0) {
      er.push('Los pasos de la formula son requeridos.');
    }
    setError(er);
    if (er.length > 0) return;

    var sum: number = 0;
    FormulaState?.map((p) => {
      if (!p.pausa) {
        sum += Number(p.valor);
      }
    });

    if (sum !== Cantidad) {
      er.push(
        'La suma de las cantidades de los pasos no es igual a la cantidad a fabricar.'
      );
      setError(er);
      return;
    }

    if (er.length === 0) {
      setisSaving(true);
      const lista: PasosLite[] = new Array();
      FormulaState?.map((p) => {
        var paso: PasosLite = {
          orden: p.orden,
          materiaprimaid: p.materiaprima ? p.materiaprima.id : -1,
          valor: p.valor!,
          minutospausa: p.minutospausa,
          pausa: p.pausa,
          observacion: p.observacion
        };
        lista.push(paso);
      });

      var newOrden: SaveOrdenFull = {
        nuevo_producto: {
          codigo: CodigoProducto || '',
          nombre: NombreProducto || '',
          descripcion: ''
        },
        formula: {
          lista_elementos: lista,
          productoid: -1,
          observacion: ''
        },
        fechaorden: new Date(),
        cantidad: Cantidad || 0,
        lote: 0,
      };

      dispatch(mapDispatchToProps.saveOrdenNew(newOrden));
      props.onAddedOrder();
    } else {
      setisSaving(false);
    }
  };

  return (
    <React.Fragment>
      {/* <div className="row col-md-12 vh-100"> */}
      <div className="contentpage">
        <div className="">
          {showForm || ProductosState?.producto ? (
            <form className="productoForm">
              <div className="">
                <label className="form-label">
                  Código de producto <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  title="Código de producto"
                  className={
                    formSubmitted && CodigoProducto === undefined
                      ? 'form-control textFieldError'
                      : 'form-control upper'
                  }
                  onChange={CodigoProductoTextFieldChangeEvent}
                />
                {formSubmitted && CodigoProducto === undefined && (
                  <small className="text-danger">
                    El código del producto es requerido
                  </small>
                )}
              </div>
              <div className="NombreProducto">
                <label className="form-label">
                  Nombre del producto <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  title="descripción del producto"
                  className={
                    formSubmitted && NombreProducto === undefined
                      ? 'form-control textFieldError'
                      : 'form-control'
                  }
                  onChange={NombreProductoTextFieldChangeEvent}
                />
                {formSubmitted && NombreProducto === undefined && (
                  <small className="text-danger">
                    El nombre del producto es requerido
                  </small>
                )}
              </div>
              <div className="">
                <label className="form-label">
                  Kilogramos <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  title="Kilogramos a utilizar del producto"
                  min="0.01"
                  className={
                    formSubmitted && Cantidad === undefined
                      ? 'form-control textFieldError'
                      : 'form-control'
                  }
                  step=".01"
                  onChange={CantidadTextFieldChangeEvent}
                />
                {formSubmitted && Cantidad === undefined && (
                  <small className="text-danger">
                    La cantidad es requerida
                  </small>
                )}
              </div>
            </form>
          ) : null}
        </div>
      </div>
      <div className="formContainer">
        {ComponentsErrors.length > 0 && (
          <div className="ErrorContainer">
            <ul>
              {ComponentsErrors.map((error: string) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        )}
        {(!FormulaState || FormulaState.length <= 0) && error.length > 0 && (
          <div className="ErrorContainer">
            <ul>
              {error.map((error: string) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        )}
        <PasosFormOrden
          FormulaId={-1}
          byAverage={false}
          ProductAmount={Cantidad}
          OnErrorEvent={OnHandleErrorFormulaComponents}
          OnchangecomponentsAmpount={(cantidad: number) =>
            setComponentesCantidad(cantidad)
          }
        />
        <div className="ordenFormFooter">
          {!isSaving ? (
            <button
              type="button"
              className={
                ComponentsErrors.length === 0
                  ? 'btn btn-primary'
                  : 'btn btn-secondary'
              }
              disabled={ComponentsErrors.length > 0 ? true : false}
              onClick={onSave}
            >
              'Generar orden de fabricación'
            </button>
          ) : (
            <>
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Generando orden...</span>
              </div>
              {'  '}&nbsp;&nbsp;Generando orden....
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.productos
});

const mapDispatchToProps = {
  ...ProductosStore.actionCreators,
  ...OrdenFabricacionStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductoFormOrden as any);
