import { useEffect, useState } from 'react';
import { ProductoTable } from '../components/producto/productoTable';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../stores';
import * as ProductosStore from '../stores/Productos';
import { useHistory } from 'react-router-dom';
import { ProductoForm } from '../components/producto/productoForm';
import { Producto } from '../model/producto';

export const ProductosPage = () => {
  const [ShowForm, setShowForm] = useState<boolean>(false);
  const [EditProduct, setEditProduct] = useState<Producto>();

  const dispatch = useDispatch();
  let history = useHistory();
  const ProductosState = useSelector(
    (state: ApplicationState) => state.productos
  );

  const ProductoSaveHandler = (savedProducto: Producto) => {
    if (savedProducto.isNew) history.push('productos/' + savedProducto.id);
    setShowForm(false);
  };

  const ProductoEditHandler = (producto: Producto) => {
    dispatch(mapDispatchToProps.setProducto());
    setEditProduct(producto);
    setShowForm(true);
  };

  useEffect(() => {
    dispatch(mapDispatchToProps.getAllProductos('0', '5000'));
  }, [dispatch]);

  return (
    <>
      <div className="pageHeader">
        <h2>Productos</h2>
        <button
          className="btn btn-primary"
          onClick={() => {
            dispatch(mapDispatchToProps.setProducto());
            setEditProduct(undefined);
            setShowForm(true);
          }}
        >
          Nuevo producto
        </button>
      </div>
      {ProductosState?.isLoading ? (
        <div className="loadingFlag">Cargando...</div>
      ) : (
        <ProductoTable EditProductEvent={ProductoEditHandler} />
      )}
      <>
        {ShowForm && (
          <div className="modalForm">
            <h5>Información del producto</h5>
            <ProductoForm
              ProductSavedEvent={ProductoSaveHandler}
              CancelEvent={() => setShowForm(false)}
              producto={EditProduct}
            />
          </div>
        )}
      </>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.productos
});

const mapDispatchToProps = {
  ...ProductosStore.actionCreators
  // ...ProductosStore.getAllProductos
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductosPage as any);
