import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import './web.config';
import App from './App';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import configureStore from './stores/configureStore';
import 'bootstrap/dist/css/bootstrap.min.css';

// auth 
import { AuthProvider } from "react-oidc-context";

const oidcConfig = {
  authority: process.env.REACT_APP_AUTHORITY,
  client_id: process.env.REACT_APP_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_REDIRECT_URL,
  granttype: process.env.REACT_APP_GRANTTYPE,
  client_secret: process.env.REACT_APP_SECRET,
  logout_redirect_uri: process.env.REACT_APP_LOGOUT_REDIRECT_URL,
  response_mode: process.env.REACT_APP_RESPONSE_MODE,
  request_mode: process.env.REACT_APP_REQUEST_MODE,
  ResponseType: process.env.REACT_APP_RESPONSE_TYPE,
};

const baseUrl = process.env.PUBLIC_URL;
const history = createBrowserHistory({ basename: baseUrl });
const store = configureStore(history);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <AuthProvider {...oidcConfig}>
        <App />
      </AuthProvider>
    </ConnectedRouter>
  </Provider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
