import { useState } from 'react';
import { TagContainerList } from './TagContainerForm/TagContainerListComponent';

export interface IProps {
  affectStock?: boolean;
  OrderId: number;
  TotalPackagedLitres: number;
  ErrorStatusChange?: (status: boolean) => void;
}

export const TagsFormComponent = (props: IProps) => {
  const [affectStock, setAffectStock] = useState<boolean>(
    props.affectStock || false
  );
  const [tagsAmount, setTagsAmounts] = useState<number>();

  const OnErrorDetectedHandler = (status: boolean) => {
    if (props.ErrorStatusChange) props.ErrorStatusChange(status);
  };

  return (
    <>
      <form>
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck1"
            onClick={() => setAffectStock(!affectStock)}
            checked={affectStock}
          />
          <label className="form-check-label" htmlFor="exampleCheck1">
            Afectar stock
          </label>
        </div>
        {affectStock ? (
          <TagContainerList
            OrderId={props.OrderId}
            TagsContainers={[]}
            TotalLitresManufatured={props.TotalPackagedLitres}
            onErrorDetected={OnErrorDetectedHandler}
          />
        ) : (
          <>
            <label>Cantidad de Etiquetas</label>
            <input
              className="form-control"
              title="Cantidad de Etiquetas"
              type="number"
            />
          </>
        )}
      </form>
    </>
  );
};
