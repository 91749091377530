import { Operator } from './../../model/operator';
import { Action, Reducer } from 'redux';
import { KnownAction } from '../Actions/OperatorActions';
import { OperatorState, unloadedState } from '../States/OperatorState';

// functions
import * as Functions from '../Functions/Commons';

export const OperatorReducer: Reducer<OperatorState> = (
  state: OperatorState | undefined,
  incomingAction: Action
): OperatorState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case 'REQUEST_ALL_OPERATORS':
      return {
        ...state,
        isLoadingAll: true,
        isLoadingAllSuccess: undefined,
        FailGettingAll: false,
        Error: undefined
      };
    case 'RECEIVE_ALL_OPERATORS':
      return {
        ...state,
        isLoadingAll: false,
        isLoadingAllSuccess: true,
        FailGettingAll: false,
        Error: undefined,
        Operators: action.operators
      };
    case 'FAIL_GET_ALL_OPERATORS':
      return {
        ...state,
        isLoadingAll: false,
        isLoadingAllSuccess: false,
        FailGettingAll: true,
        Error: action.Error
      };
    case 'REQUEST_OPERATOR':
      return {
        ...state,
        isLoadingOne: true,
        isLoadingOneSuccess: undefined,
        FailGettingOne: false,
        Error: undefined
      };
    case 'RECEIVE_OPERATOR':
      return {
        ...state,
        isLoadingOne: false,
        isLoadingOneSuccess: true,
        FailGettingOne: false,
        Error: undefined,
        SelectedOperator: action.operator
      };
    case 'FAIL_GET_OPERATOR':
      return {
        ...state,
        isLoadingOne: false,
        isLoadingOneSuccess: false,
        FailGettingOne: true,
        Error: action.Error
      };
    case 'REQUEST_ADD_OPERATOR':
      return {
        ...state,
        isAdding: true,
        isAddingSuccess: undefined,
        FailAdding: false,
        Error: undefined
      };
    case 'RECEIVE_ADDED_OPERATOR':
      return {
        ...state,
        isAdding: false,
        isAddingSuccess: true,
        FailAdding: false,
        Error: undefined,
        Operators: [...state.Operators, action.operator].sort(
          Functions.DynamicSort('Apellido')
        ),
        SelectedOperator: action.operator
      };
    case 'FAIL_ADD_OPERATOR':
      return {
        ...state,
        isAdding: false,
        isAddingSuccess: false,
        FailAdding: true,
        Error: action.Error
      };
    case 'REQUEST_SAVE_OPERATOR':
      return {
        ...state,
        isSaving: true,
        isSavingSuccess: undefined,
        FailSaving: false,
        Error: undefined
      };
    case 'RECEIVE_SAVED_OPERATOR':
      return {
        ...state,
        isSaving: false,
        isSavingSuccess: true,
        FailSaving: false,
        Error: undefined,
        Operators: [
          ...state.Operators.filter(
            (operator: Operator) => operator.id !== action.operator.id
          ),
          action.operator
        ].sort(Functions.DynamicSort('Apellido')),
        SelectedOperator: action.operator
      };
    case 'FAIL_SAVE_OPERATOR':
      return {
        ...state,
        isSaving: false,
        isSavingSuccess: false,
        FailSaving: true,
        Error: action.Error
      };
  }

  return state;
};
