import { ApplicationState } from '../../../stores';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { IReplacementCost } from '../../../model/replacementCost';
import { bindActionCreators } from 'redux';
import { ErrorType } from '../../../model/FetchError';

//store
import * as ProductStore from '../../../stores/Productos';

//style
import './ReplacementCostComponent.css';
import NumberFormat from 'react-number-format';

//Utils
import { formatCurrency } from '../../../utils/formatters';

export interface IProps {
  productId: number;
}

interface ConnectedProps {
  isLoading: boolean;
  successLoading: boolean | undefined;
  failOnLoading: boolean;
  error: ErrorType | undefined;
  cost: IReplacementCost;
}

interface DispatchProps {
    GetReplacementCost: (productId: number) => void;
}

export type Props = IProps & ConnectedProps & DispatchProps;

export const ReplacementCostComponent = ({
  isLoading,
  successLoading,
  failOnLoading,
  error,
  cost,
  productId,
  GetReplacementCost,
  ...props
}: Props) => {
  useEffect(() => {
    GetReplacementCost(productId);
  }, [GetReplacementCost, productId]);

  const status = isLoading ? (
    <>Cargando...</>
  ) : failOnLoading ? (
    <div className="seccion productCost">
      <label>Costo por litro a 1</label>
      <h6>{error?.ErrorMessage}</h6>
      <hr />
      <small className="text-info">
        Costo determinado en base al valor de las materias primas acorde la último remito cargado
      </small>
    </div>
  ) : (
    <>Dio error</>
  );

  return !successLoading ? (
    status
  ) : (
    <div className='d-flex flex-column align-items-center'>
      <label className='text-center mb-2'>Costo de reposicion</label>
      <div className="seccion productCost">
        <label>Costo por litro</label>
        <h4>
          {cost?.costoLitro && <> <NumberFormat
            // value={Number.parseFloat(cost?.costoLitro?.toString())?.toFixed(2)}
            value={formatCurrency(cost?.costoLitro)}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
          />
          {' U$D/Ltr'}
          </>}
          {!cost?.costoLitro && <>No se ha determinado el costo de reposición</>}
        </h4>
        <hr />
        <small className="text-info">
          Costo determinado en base a la última orden de fabricación
        </small>
      </div>
    </div>
  
   
  );
};

const mapStateToProps = (state: ApplicationState, ownProps: IProps) => ({
  isLoading: state.productos?.isGettingReplacementCost,
  successLoading: state.productos?.GettingReplacementCostSuccess,
  failOnLoading: state.productos?.failOnGettingReplacementCost,
  error: state.productos?.Error,
  cost: state.productos?.ReplacementCost
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
        GetReplacementCost: ProductStore.actionCreators.GetReplacementCost
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReplacementCostComponent as any);
