import { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formula } from '../model/formula';
import { FormulaTable } from '../components/formula/formulaTable';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../stores';
import * as FormulaStore from '../stores/Formula';
import * as ProductosStore from '../stores/Productos';
import { useForm } from 'react-hook-form';
import { ListaFormula } from '../model/lista_formula';
export const FormulaPage = () => {
  const FormulaState = useSelector((state: ApplicationState) => state.formulas);
  const productos = useSelector(
    (state: ApplicationState) => state.productos?.productos
  );

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const handleClose = () => setShow(false);
  const saveFormula = () => {
    var newFormula: Formula = {
      id: getValues('id'),
      productoid: getValues('productoid'),
      observacion: getValues('observacion'),
      lista_elementos: []
    };

    dispatch(mapDispatchToProps.saveFormula(newFormula));
    handleClose();
  };

  useEffect(() => {
    dispatch(mapDispatchToProps.getAllFormulas());
  }, []);
  // useEffect(() => {
  //   if (!FormulaState?.formulas) dispatch(mapDispatchToProps.getAllFormulas());
  // }, [FormulaState]);

  const defaultValues = {
    id: -1,
    productoId: -1,
    observacion: ''
  };
  const {
    register,
    reset,
    getValues,
    handleSubmit,
    formState: { errors }
  } = useForm<Formula>({
    defaultValues: defaultValues
  });

  const onSubmit = (data: any) => {
    if (getValues('productoid') == -1) {
      setError(true);
      return;
    }
    setError(false);
    saveFormula();
    //dispatch(mapDispatchToProps.saveMateriaPrima(data));
  };

  const editFormula = (value: ListaFormula) => {
    dispatch(mapDispatchToProps.getAllProductos('0', '5000'));
    setShow(true);
    setError(false);
    reset({
      id: value.id,
      productoid: value.productoid,
      observacion: value.observacion
    });
  };
  // const RouteChange = () => {
  //   let path = "formula/detalle/-1";
  //   history.push(path);
  // };
  return (
    <div>
      <h1>Formula</h1>
      <div className="row col-md-12">
        <div className="col-md-12 float-right">
          <button
            type="button"
            className="btn btn-primary mb-2 float-right"
            onClick={() => {
              dispatch(mapDispatchToProps.getAllProductos('0', '5000'));
              reset(defaultValues);
              setShow(true);
            }}
          >
            Agregar Formula
          </button>
        </div>
        <>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Nueva Formula</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Modal.Body>
                <div className="col-md-12">
                  <div className="col-md-12">
                    <div className="col-md-12 mb-2">
                      <Form.Select
                        aria-label="Default select example"
                        {...register('productoid', {
                          required: 'select one option'
                        })}
                      >
                        <option value="-1">Seleccione</option>
                        {productos
                          ? productos.map((option) => (
                              <option key={option.id} value={option.id}>
                                {' '}
                                {option.codigo} - {option.descripcion}
                              </option>
                            ))
                          : null}
                      </Form.Select>
                      {error ? 'El producto es requerido' : null}
                      {errors.productoid?.type === 'required' &&
                        'La producto es requerido'}
                      {/* { getValues('productoId') ==-1 && "El codigo es requerido"} */}
                    </div>
                    <div className="col-md-12 mb-2">
                      <input
                        type="text"
                        placeholder="Observacion"
                        className="form-control"
                        {...register('observacion', {
                          required: true,
                          maxLength: 250,
                          minLength: 3
                        })}
                      />
                      {errors.observacion?.type === 'required' &&
                        'La observacion es requerida'}
                      {errors.observacion?.type === 'minLength' &&
                        'La observacion es muy chica'}
                      {errors.observacion?.type === 'maxLength' &&
                        'La observacion es muy grande'}
                    </div>
                    {/* <button type="submit" className="btn btn-primary mb-2 float-right">
              Grabar
            </button> */}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Cancelar
                </Button>
                <Button type="submit" variant="primary">
                  Grabar
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
        </>
        <FormulaTable
          dataState={FormulaState?.formulas}
          editFormula={editFormula}
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  ...state.formulas
});

const mapDispatchToProps = {
  ...FormulaStore.actionCreators,
  ...ProductosStore.actionCreators
};

export default connect(mapStateToProps, mapDispatchToProps)(FormulaPage as any);
