import { MenuItem } from './MenuItem';
import { MenuItems } from './MenuItems';
import * as jwt_decode from "jwt-decode";

// import styles
import './MenuComponent.css';
import { NavLink } from 'react-router-dom';

// auth
import { User } from "oidc-client-ts"

export interface IProps {
  Group: number;
}

function getUser() {
  const oidcStorage = sessionStorage.getItem(`oidc.user:https://keycloak.infra.idbnar.com/realms/Expocolor/:` + process.env.REACT_APP_CLIENT_ID);
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

export const MenuComponent = (props: IProps) => {

  const user = getUser();
  const token = user?.access_token;
  const roles = (jwt_decode.jwtDecode(token || '')as any)?.realm_access?.roles;

  return (
    <div className="menuContent">
      {MenuItems.filter(
        (menuItem: MenuItem) => menuItem.Group === props.Group
      ).map((option: MenuItem) => {

        if(option.roles.length > 0 && option.roles.some((role: string) => roles?.includes(role))) {
          return (
            <NavLink
              to={option.location}
              className="menuItem"
              activeClassName="active"
            >
              {option.icon()}
              <label>{option.title}</label>
            </NavLink>
          )
        }
        
      })}
    </div>
  );
};
