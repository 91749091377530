import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ActionMeta, SingleValue } from 'react-select';
import Select from 'react-select';
import { MateriaPrima } from '../../../../model/materia_prima';
import { ApplicationState } from '../../../../stores';

//stores
import * as RawMaterialsStore from '../../../../stores/Materia_Prima';

//styles
import './TagContainer.css';
import { FaPlus } from 'react-icons/fa';
import { TagContainer } from '../../../../model/TagContainer';

export interface IProps {
  OrderId: number;
  OnAddTagEvent?: (Tag: TagContainer) => void;
  AllReadyAddedTags?: TagContainer[];
}

export const TagSelectionContainerFormComponent = (props: IProps) => {
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const TagState = useSelector((state: ApplicationState) => state.Supplies);

  const dispatch = useDispatch();

  // form fields
  const [TagContainerSelected, setTagContainerSelected] =
    useState<MateriaPrima>();
  const [cantidad, setCantidad] = useState<number>();

  useEffect(() => {
    dispatch(mapDispatchToProps.GetAllSupplies(0, 5000));
  }, [dispatch]);

  const ValidateForm = (): boolean => {
    let validationErrors: string[] = [];
    if (!TagContainerSelected) {
      validationErrors = [
        ...validationErrors,
        'Debe seleccionar una materia prima'
      ];
    }

    if (!cantidad) {
      validationErrors = [
        ...validationErrors,
        'Debe informar una cantida de materia prima utilizada'
      ];
    }

    // duplication validate in case that AllReadyAddedComponents props was passed
    if (props.AllReadyAddedTags) {
      const TagContainerFinded: TagContainer | undefined =
        props.AllReadyAddedTags.find((tagContainerAlreadyAdded: TagContainer) =>
          tagContainerAlreadyAdded.materiaprimaid === TagContainerSelected?.id
            ? true
            : false
        );

      if (TagContainerFinded)
        validationErrors = [
          ...validationErrors,
          'El tipo de envase ya fue agregado'
        ];
    }

    setErrors(validationErrors);

    if (validationErrors.length > 0) return false;
    else return true;
  };

  const ResetForm = () => {
    setFormSubmitted(false);
    setCantidad(undefined);
    setErrors([]);
    setTagContainerSelected(undefined);
  };

  // fields changes handlers
  const OnFormSubmitHandler = () => {
    setFormSubmitted(true);
    if (ValidateForm()) {
      props.OnAddTagEvent &&
        props.OnAddTagEvent({
          cantidad: cantidad!,
          materiaprimaid: TagContainerSelected!.id,
          materiaprima: TagContainerSelected,
          ordenfabricacionid: props.OrderId
        });
      ResetForm();
    }
  };

  return (
    <>
      {errors.length > 0 && (
        <div className="ErrorContainer">
          <ul>
            {errors.map((error: string) => (
              <li>{error}</li>
            ))}
          </ul>
        </div>
      )}
      <form className="formOrderAdditionalComponents">
        <div>
          <label className="form-label">
            Envase<span className="text-danger">*</span>
          </label>
          <Select
            className={
              formSubmitted && TagContainerSelected === undefined
                ? 'textFieldError'
                : ''
            }
            value={TagState?.Containers?.filter((rawMateria: MateriaPrima) =>
              rawMateria.id === TagContainerSelected?.id ? true : false
            )}
            options={TagState?.Containers}
            placeholder="Seleccione..."
            getOptionLabel={(rawMateriaItem: MateriaPrima) =>
              rawMateriaItem.codigo && rawMateriaItem.nombre
                ? rawMateriaItem.codigo + ' - ' + rawMateriaItem.nombre
                : rawMateriaItem.codigo
            }
            getOptionValue={(rawMateria: MateriaPrima) =>
              rawMateria.id.toString()
            }
            onChange={(
              newValue: SingleValue<MateriaPrima>,
              actionMeta: ActionMeta<MateriaPrima>
            ) => setTagContainerSelected(newValue! as MateriaPrima)}
          />
          {formSubmitted && TagContainerSelected === undefined && (
            <small className="text-danger">Dato requerido</small>
          )}
        </div>
        <div>
          <label className="form-label">
            Cantidad <span className="text-danger">*</span>
          </label>
          <input
            type="number"
            title="Cantidad de envases"
            min="0.01"
            className={
              formSubmitted && cantidad === undefined
                ? 'form-control textFieldError'
                : 'form-control'
            }
            step=".01"
            value={cantidad || ''}
            onChange={(event: any) => setCantidad(Number(event.target.value))}
          />
          {formSubmitted && cantidad === undefined && (
            <small className="text-danger">Dato requerido</small>
          )}
        </div>
        <div className="formButtons baseBottom">
          <button
            type="button"
            className="btn btn-primary"
            onClick={OnFormSubmitHandler}
            title="Agregar"
          >
            <FaPlus />
          </button>
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.Supplies
});

const mapDispatchToProps = {
  ...RawMaterialsStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagSelectionContainerFormComponent as any);
