import { ErrorType } from '../../../model/FetchError';
import { ApplicationState } from '../../../stores';

//stores 
import * as RemitoStore from '../../../redux/Middleware/RemitoMiddleware';
import { Remito } from '../../../model/Remito/Remito';


//#region Props

interface IProps {
    RemitoId: number | undefined;
    MateriaPrimaId: number | undefined;
    onSaveRemito: (remito: Remito | undefined) => void;
    onCancel: () => void;
}

interface ConnectedProps {
    // loading
    isLoading: boolean | undefined;
    LoadingSuccessfully: boolean | undefined;
    failOnLoading: boolean | undefined;
    // Adding
    isCreating: boolean;
    CreateSuccessfully: boolean;
    failOnCreating: boolean;
    // Updating
    isUpdating: boolean;
    UpdateSuccessfully: boolean;
    failOnUpdating: boolean;

    error: ErrorType | undefined;
    remito: Remito | undefined;
}

interface MedicalServicesDispatchProps {
    Get: typeof RemitoStore.actionCreators.Get;
    Create: typeof RemitoStore.actionCreators.Create;
    Update: typeof RemitoStore.actionCreators.Update;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: ApplicationState, ownProps: IProps) => ({
    // loading
    isLoading: state.remitos?.isLoadingOne,
    LoadingSuccessfully: state.remitos?.isLoadingOneSuccess,
    failOnLoading: state.remitos?.FailGettingOne,
    // Adding
    isCreating: state.remitos?.isAdding,
    CreateSuccessfully: state.remitos?.isAddingSuccess,
    failOnCreating: state.remitos?.FailAdding,
    // Updating
    isUpdating: state.remitos?.isSaving,
    UpdateSuccessfully: state.remitos?.isSavingSuccess,
    failOnUpdating: state.remitos?.FailSaving,

    error: state.remitos?.Error,
    remito: ownProps.RemitoId ? state.remitos?.list?.find(x => x.id === ownProps.RemitoId) : undefined
});

export const mapDispatchToProps = {
    ...RemitoStore.actionCreators
};

//#endregion Props