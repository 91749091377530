import { MateriaPrima } from '../../model/materia_prima';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../stores';
import * as SuppliesStore from '../../stores/Materia_Prima';
import * as StockStore from '../../stores/Stock';
import { Alert } from 'react-bootstrap';

import '../../custom.css';
import './materiaprimaTable.css';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MeasurementUnit } from '../../model/MeasurementUnit';
import { MeasurementUnitType } from '../../model/enums/MeasurementUnitsEnum';
import NumberFormat from 'react-number-format';
import { idText } from 'typescript';
import RemitoFormComponent from '../Remito/Form/RemitoForm.Component';

export interface IProps {
  EditSupplyEvent?: (supply: MateriaPrima) => void | undefined;
}

export const MateriaPrimaTable = (props: IProps) => {
  const [showAlert, setShowAlert] = useState(false);
  const SuppliesState = useSelector(
    (state: ApplicationState) => state.Supplies
  );

  const [selectedMateriaPrima, setSelectedMateriaPrima] = useState<MateriaPrima>();

  const AuthState = useSelector(
    (state: ApplicationState) => state.authentication?.authenticatedUser
  );

  const StockState = useSelector((state: ApplicationState) => state.Stock);

  const history = useHistory();
  const [SearchText, setSearchText] = useState<string>('');
  const dispatch = useDispatch();

  const pressEnterHandler = (e: any) => {
    return e.key === 'Enter' ? searchHandler() : e;
  };

  const EditMateriaPrimaHandler = (supply: MateriaPrima) => {
    if (props.EditSupplyEvent) props.EditSupplyEvent(supply);
    else return null;
  };

  const DeleteMateriaPrimaHandler = (supply: MateriaPrima) => {
    if (AuthState && AuthState.jwToken) {
      dispatch(mapDispatchToProps.DeleteSupply(supply.id, AuthState!.jwToken!));
      setShowAlert(true);
    }
  };

  const AddRemito = (supply: MateriaPrima) => {
    setSelectedMateriaPrima(supply);
  }

  const ViewMateriaPrimaInfoHandler = (mareriaprima: MateriaPrima) => {
    history.push('supplies/' + mareriaprima.id);
  };
  const searchHandler = () => {
    dispatch(mapDispatchToProps.GetAllSupplies(0, 5000));
  };

  const removeFilterHandler = () => {
    setSearchText('');
  };

  useEffect(() => {
    dispatch(mapDispatchToProps.GetMeasurementUnit(MeasurementUnitType.all));
  }, []);

  const SearchBar = (
    <div className="searchBar">
      <div className="input-group">
        <input
          type="text"
          placeholder="Ingrese el texto a buscar..."
          className="form-control"
          defaultValue=""
          onKeyDown={pressEnterHandler}
          onChange={(e) => setSearchText(e.target.value)}
          value={SearchText}
        />
        {SearchText !== '' && (
          <button
            className="input-group-text"
            id="basic-addon2"
            onClick={removeFilterHandler}
          >
            X
          </button>
        )}
      </div>
      {/* <button className="btn btn-primary" onClick={searchHandler}>
        Buscar
      </button> */}
    </div>
  );

  const GettingSuccessfully = (
    <>
      <h5>Materias primas registradas</h5>
      <div className="seccion">
        {SearchBar}
        <hr></hr>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Código</th>
              <th>Descripción</th>
              <th>Unidad de Medida</th>
              <th>Proveedor</th>
              <th>Comentarios</th>
              <th>Precio referencia</th>
              <th>Precio de reposición</th>
              <th>Stock</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            {SuppliesState?.Supplies?.filter((supply: MateriaPrima) =>
              SearchText !== ''
                ? supply.codigo
                    .toLocaleLowerCase()
                    .indexOf(SearchText.toLocaleLowerCase()) !== -1 ||
                  supply.nombre
                    .toLocaleLowerCase()
                    .indexOf(SearchText.toLocaleLowerCase()) !== -1
                : true
            ).map((supply: MateriaPrima) => (
              <tr key={supply.id}>
                <td>
                  <strong className="upper">{supply.codigo}</strong>
                </td>
                <td>
                  <label className="upper">{supply.nombre}</label>{' '}
                  {supply.esEnvase && (
                    <span className="badge bg-secondary">
                      Envase {supply.capacidad}{' '}
                      {supply.unidadDeMedidaCapacidad.codigo}.
                    </span>
                  )}
                </td>
                <td>
                  {supply.unidadDeMedida
                    ? StockState?.MeasurementUnits.find(
                        (unit: MeasurementUnit) =>
                          unit.id === supply.unidadDeMedidaId
                      )?.descripcion
                    : 'No Informada'}
                </td>
                <td>
                  <label>
                    <span className="upper">
                      {supply.codigoproveedor
                        ? '[' + supply.codigoproveedor + '] - '
                        : ''}
                    </span>
                    {supply.descripcionproveedor}
                  </label>
                </td>
                <td>
                  <p>{supply.descripcion}</p>
                </td>
                <td>
                  <NumberFormat
                    value={supply.precioReferencia}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                  />{' '}
                  U$D/
                  {supply.unidadDeMedida.codigo}
                </td>
                <td>
                <NumberFormat
                    value={supply.precioReposicion}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                  />{' '}
                  U$D/
                  {supply.unidadDeMedida.codigo}
                </td>
                <td
                  className={
                    supply.minimoStockAceptable &&
                    supply.stock &&
                    supply.minimoStockAceptable > supply.stock.cantidad
                      ? 'StockBajo'
                      : 'StockOk'
                  }
                >
                  <NumberFormat
                    value={supply.stock.cantidad}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                  />
                  &nbsp;
                  {supply.unidadDeMedida.codigo}
                  {supply.minimoStockAceptable &&
                    supply.stock &&
                    supply.stock.cantidad > 0 &&
                    supply.minimoStockAceptable >= supply.stock.cantidad &&
                    supply.stock.cantidad > 0 && (
                      <p>
                        <small className="badge bg-warning">Stock Bajo</small>
                      </p>
                    )}
                  {supply.minimoStockAceptable &&
                    supply.minimoStockAceptable >= supply.stock.cantidad &&
                    supply.stock.cantidad <= 0 && (
                      <p>
                        <strong className="badge bg-danger">SIN STOCK</strong>
                      </p>
                    )}
                </td>
                <td>
                  <div className="controls">
                  <button
                      type='button'
                      className="btn btn-outline-secondary btn-sm"
                      onClick={(e: any) => { e.preventDefault(); AddRemito(supply);}}
                    >
                      Ingresar Stock
                    </button>
                    <button
                      className="btn btn-outline-secondary btn-sm"
                      onClick={() => EditMateriaPrimaHandler(supply)}
                    >
                      Editar
                    </button>
                    <button
                      className="btn btn-outline-secondary btn-sm"
                      onClick={() => ViewMateriaPrimaInfoHandler(supply)}
                    >
                      Información
                    </button>
                    <button
                      className="btn btn-outline-danger btn-sm"
                      onClick={() => DeleteMateriaPrimaHandler(supply)}
                    >
                      Borrar
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {SuppliesState?.Supplies?.length === 0 && (
          <label>No hay materias primas registradas</label>
        )}
      </div>

      {/* {SuppliesState?.Supplies?.map((supply: MateriaPrima) => (
        <>
          <div className="tableItem">
            <div className="tableItemHeader">
              <small>Código</small>
              <h2>
                {supply.codigo}
                {'  '}
                {supply.esEnvase && (
                  <span className="badge bg-secondary">
                    Envase {supply.capacidad}lts
                  </span>
                )}
              </h2>
              <label>{supply.nombre}</label>
            </div>
            <div className="tableItemHeader">
              <small>Proveedor</small>
            </div>
            <div className="tableItemHeader">
              <small>Comentarios</small>
              <p>{supply.descripcion}</p>
            </div>
            <div className="controls">
              <button onClick={() => EditMateriaPrimaHandler(supply)}>
                Editar
              </button>
              <button onClick={() => ViewMateriaPrimaInfoHandler(supply)}>
                Información
              </button>
            </div>
          </div>
        </>
      ))} */}
    </>
  );

  const GettingFail = (
    <div className="ErrorContainer">
      <label>{SuppliesState?.Error?.ErrorMessage}</label>
      {SuppliesState?.Error?.Errors && (
        <ul>
          {SuppliesState?.Error?.Errors.map((error: string) => (
            <li>{error}</li>
          ))}
        </ul>
      )}
    </div>
  );

  const internalForms = (
    <RemitoFormComponent 
      RemitoId={undefined} 
      MateriaPrimaId={selectedMateriaPrima?.id}
      onSaveRemito={() => setSelectedMateriaPrima(undefined) }
      onCancel={() => setSelectedMateriaPrima(undefined)} 
      />

  )

  return (
    <>
      <div className="contentpage">
        {SuppliesState && SuppliesState.isFiltered && (
          <Alert variant="warning">
            Filtro aplicado: <strong>{SuppliesState.filterCriteria}</strong>
          </Alert>
        )}
        {SuppliesState?.FailDeletingOne && SuppliesState.Error && showAlert && (
          <Alert variant="danger" className="alert-dismissible stycky-top">
            <label>Atención</label>
            {SuppliesState.Error.Errors && (
              <ul>
                {SuppliesState.Error.Errors.map((error: string) => (
                  <li>{error}</li>
                ))}
              </ul>
            )}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Cerrar"
              onClick={() => setShowAlert(false)}
            ></button>
          </Alert>
        )}
        {SuppliesState?.isLoadingAllSuccess && GettingSuccessfully}
        {SuppliesState?.FailGettingAll && GettingFail}
        {selectedMateriaPrima && (
          <div className="modalForm">
          <h5>Información del remito</h5>
            {internalForms}
        </div>)}
      </div>
    </>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  ...state.Supplies,
  ...state.Stock,
  ...state.authentication
});

const mapDispatchToProps = {
  ...SuppliesStore.actionCreators,
  ...StockStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MateriaPrimaTable as any);
