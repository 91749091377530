import React, { useState } from 'react';

interface Pagination {
  Pagination: {
    pageNumber: number;
    pageSize: number;
    totalPages: number;
    totalRecords: number;
  };

  onPageChange: (pageNumber: number) => void;
}

const OrderPagination: React.FC<Pagination> = ({ Pagination, onPageChange }) => {
  const { pageNumber, pageSize, totalPages, totalRecords } = Pagination;

  const handlePageChange = (newPageNumber: number) => {
    onPageChange(newPageNumber);
  };

  const renderPaginationItems = () => {
    const items = [];
    const maxPagesToShow = 20; // Define el número máximo de paginas;
    let startPage = 1;
    let endPage = totalPages;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);// si hay más páginas que el límite, calcula el rango a mostrar
    
  
    if (totalPages > maxPagesToShow) {
      
      startPage = Math.max(pageNumber - halfMaxPagesToShow, 1);
      endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);
      if ((endPage - startPage + 1) + halfMaxPagesToShow < maxPagesToShow) {
        // Ajusta el rango si está cerca de los extremos
        startPage = Math.max(endPage - maxPagesToShow + 1, 1);
      }
    }
  
    const calculatedPage = startPage + halfMaxPagesToShow;
    // Agrega botn para retroceder al conjunto anterior de páginas si es necesario;
    if (calculatedPage > maxPagesToShow) {
      items.push(
        <button
          key="previousSet"
          className="btn btn-sm btn-light border-primary"
          onClick={() => handlePageChange((calculatedPage) - maxPagesToShow)}
        >
          {`${(calculatedPage) - maxPagesToShow}-${startPage - 1}`}
        </button>
      );
    }
  
    for (let i = startPage; i <= endPage; i++) {
      const isActive = i === pageNumber;
      items.push(
        <li><button
          key={i}
          className={`btn btn-sm ${isActive ? 'btn-primary' : 'btn-light'}`}
          onClick={() => handlePageChange(i)}
          disabled={isActive} // Deshabilitar el botón seleccionado;
        >
          {i}
        </button>
        </li>
      );
    }
  
    if (totalPages > maxPagesToShow) {
      // Agrega un botón para mostrar el siguiente conjunto de páginas si es necesario
      const nextPagesStart = endPage + 1;
      const nextPagesEnd = Math.min(nextPagesStart + maxPagesToShow - 1, totalPages);
      if (nextPagesStart <= totalPages) {
        items.push(
          <button
            key="nextSet"
            className="btn btn-sm btn-light border-primary"
            onClick={() => handlePageChange(nextPagesStart)}
          >
            {`${nextPagesStart}-${nextPagesEnd}`}
          </button>
        );
      }
    }
  
    return items;
  };

  return (
    <div className="d-flex justify-content-between mt-0 pt-0">
      <p>
        Mostrando {Math.min(pageSize * pageNumber, totalRecords)} de {totalRecords}{' '}
        registros
      </p>
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center" style={{display: "ruby-text"}}>
          <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
            <button className="page-link me-2" onClick={() => handlePageChange(pageNumber - 1)}>
              Anterior
            </button>
          </li>
          {renderPaginationItems()}
          <li className={`page-item ${pageNumber === totalPages ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(pageNumber + 1)}>
              Siguiente
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default OrderPagination;
