import { NewStockTransaction, StockTransaction } from './../model/Stock';
import { ApplicationState } from './index';
import { MeasurementUnit } from './../model/MeasurementUnit';
import { AppThunkAction } from '.';
import { MeasurementUnitType } from '../model/enums/MeasurementUnitsEnum';
import { IResult } from '../model/Result';
import { Stock } from '../model/Stock';

// Actions
import { KnownAction } from '../redux/Actions/StockActions';

// Functions
import * as Functions from '../redux/Functions/Commons';

const BaseURL = process.env.REACT_APP_API_ENDPOINT + 'v1/stocks';

export const actionCreators = {
  GetMeasurementUnit:
    (category: MeasurementUnitType): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const currentState: ApplicationState = getState();
      if (currentState.Stock?.MeasurementUnits.length === 0) {
        dispatch({
          type: 'REQUEST_MEASUREMENT_UNITS',
          Category: category
        });

        let RequestURL: string = BaseURL + '/parametrias/unidadesdemedidas';

        await fetch(RequestURL)
          .then((response) => {
            console.log(response);
            if (!response.ok) {
              throw response;
            } else {
              return response.json() as Promise<IResult<MeasurementUnit[]>>;
            }
          })
          .then((data: IResult<MeasurementUnit[]>) =>
            dispatch({
              type: 'RECEIVE_MEASUREMENT_UNITS',
              MeasurementUnits: data.data
            })
          )
          .catch((error: any) =>
            error.text().then((body: any) => {
              dispatch({
                type: 'FAIL_GET_MEASUREMENT_UNITS',
                Error: Functions.HttpErrorHandler(body, error)
              });
            })
          );
      }
    },
  GetStock:
    (stockId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({
        type: 'REQUEST_STOCK',
        StockId: stockId
      });

      let RequestURL: string = BaseURL + '/stocks/' + stockId;

      await fetch(RequestURL)
        .then((response) => {
          if (!response.ok) {
            throw response;
          } else {
            return response.json() as Promise<IResult<Stock>>;
          }
        })
        .then((data: IResult<Stock>) => {
          dispatch({
            type: 'RECEIVE_STOCK',
            Stock: data.data
          });
        })
        .catch((error: any) => {
          error.text().then((body: any) => {
            dispatch({
              type: 'FAIL_GET_STOCK',
              Error: Functions.HttpErrorHandler(body, error)
            });
          });
        });
    },
  AddNewStockTransaction:
    (newTransaction: NewStockTransaction): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({
        type: 'REQUEST_ADD_STOCK_TRANSACTION',
        NewStockTransaction: newTransaction
      });

      let RequestURL: string =
        BaseURL + '/stocks/' + newTransaction.stockid + '/movimientos';

      await fetch(RequestURL, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(newTransaction)
      })
        .then((response) => {
          if (!response.ok) {
            throw response;
          } else {
            return response.json() as Promise<IResult<StockTransaction>>;
          }
        })
        .then((data: IResult<StockTransaction>) => {
          dispatch({
            type: 'RECEIVE_ADDED_STOCK_TRANSACTION',
            StockTransaction: data.data
          });
        })
        .catch((error: any) =>
          error.text().then((body: any) => {
            dispatch({
              type: 'FAIL_ADD_STOCK_TRANSACTION',
              Error: Functions.HttpErrorHandler(body, error)
            });
          })
        );
    }
};
