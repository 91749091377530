import { ApplicationState } from '../../../../stores';
import { connect, useDispatch, useSelector } from 'react-redux';

// styles
import './OrdenFabricacionEnCursoListComponent.css';

// store
import * as OrdenesFabricacionStore from '../../../../stores/OrdenFabricacion';
import { useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';
import { OrdenFabricacionEnCursoItem } from './OrdenFabricacionEnCursoItemComponent';
import { Orden } from '../../../../model/orden';

interface OdenFabricacionEnCursoListProps {
  isColor: boolean;
}

export const OdenFabricacionEnCursoList: React.FC<OdenFabricacionEnCursoListProps> = ({ isColor }) => {
  const dispatch = useDispatch();
  const OrdenesFabricacionState = useSelector(
    (state: ApplicationState) => state.ordenes
  );

  useEffect(() => {
    dispatch(mapDispatchToProps.getAllOrdenes(1, 5000, false));
  }, []);

  return (
    <div className="OrdenesWidgetCard">
      <label className="ContentTitle">{isColor ? 'Colores' : 'Producto stardard'}</label>
      <label className="SubContentTitle">Ordenes de fabricación en curso</label>
      <ListGroup>
        {OrdenesFabricacionState?.ordenes?.filter((order) => !order.finalizado ? true : false )
        .filter((order) =>
          isColor 
            ? order.codigoProducto.startsWith('R') 
            : !order.codigoProducto.startsWith('R')
        )?.sort(
            (a: Orden, b: Orden) =>
              new Date(b.fechaorden).getTime() - new Date(a.fechaorden).getTime()
          ).map((pendingOrder: Orden) => (
            <ListGroup.Item>
              <OrdenFabricacionEnCursoItem order={pendingOrder} />
            </ListGroup.Item>
          ))}
      </ListGroup>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.ordenes
});

const mapDispatchToProps = {
  ...OrdenesFabricacionStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OdenFabricacionEnCursoList as any);
