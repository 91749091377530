//styles
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { TagContainer } from '../../../../model/TagContainer';
import { TagSelectionContainerFormComponent } from './TagSelectContainerFormComponent';

export interface IProps {
  OrderId: number;
  TagsContainers: TagContainer[];
  TotalLitresManufatured: number;
  onAddTagContainer?: (newTagContainer: TagContainer) => void;
  onDeleteTagContainer?: (DeletedTagContainer: TagContainer) => void;
  onErrorDetected?: (status: boolean) => void;
}

export const TagContainerList = (props: IProps) => {
  const [tagContainerList, setTagContainerList] = useState<TagContainer[]>(
    props.TagsContainers || []
  );

  const [packagedLitres, setPackagedLitres] = useState<number>(0);

  useEffect(() => {
    let TotalPackagedLitres: number = 0;
    tagContainerList.map(
      (item: TagContainer) =>
        (TotalPackagedLitres += item.cantidad * item.materiaprima?.capacidad!)
    );
    setPackagedLitres(TotalPackagedLitres);
    if (props.onErrorDetected) {
      if (TotalPackagedLitres > props.TotalLitresManufatured)
        props.onErrorDetected(true);
      else props.onErrorDetected(false);
    }
  }, [tagContainerList, props.TotalLitresManufatured]);

  // just in case that props AdditionalRawMaterials be updated from an external process
  // useEffect(() => {
  //   const ConcatenatedArray: AdditionalRawMaterial[] =
  //     props.AdditionalRawMaterials.concat(additionalRawMaterialsList);

  //   setAdditionalRawMaterialList(
  //     ConcatenatedArray.filter(
  //       (item: AdditionalRawMaterial, pos: number) =>
  //         ConcatenatedArray.indexOf(item) === pos
  //     )
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.AdditionalRawMaterials]);

  //add material handler
  const OnAddTagContainerHandler = (newTagContainer: TagContainer) => {
    setTagContainerList([...tagContainerList, newTagContainer]);
    if (props.onAddTagContainer) props.onAddTagContainer(newTagContainer);
  };

  const OnDeleteTagContainerHandler = (DeletedTagContainer: TagContainer) => {
    setTagContainerList(
      tagContainerList.filter((item: TagContainer) =>
        item.materiaprimaid === DeletedTagContainer.materiaprimaid
          ? false
          : true
      )
    );

    if (props.onDeleteTagContainer)
      props.onDeleteTagContainer(DeletedTagContainer);
  };

  return (
    <>
      <TagSelectionContainerFormComponent
        OrderId={props.OrderId}
        OnAddTagEvent={OnAddTagContainerHandler}
        AllReadyAddedTags={tagContainerList}
      />
      {packagedLitres > props.TotalLitresManufatured && (
        <div className="ErrorContainer">
          <ul>
            <li>La cantidad de litros envasados es mayor que la fabricada</li>
          </ul>
        </div>
      )}
      <label>
        Litros envasados totales: <strong>{packagedLitres}ltr.</strong>
      </label>
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Envase</th>
            <th>Cantidad</th>
            <th>Litros</th>
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
          {tagContainerList.map((item: TagContainer) => {
            const TotalLitres = item.cantidad * item.materiaprima?.capacidad!;
            return (
              <tr key={item.materiaprimaid}>
                <td>
                  <h5>{item.materiaprima?.codigo}</h5>
                  <label>{item.materiaprima?.nombre}</label>
                </td>
                <td>
                  <h4>{item.cantidad}</h4>
                </td>
                <td>
                  <h4>{TotalLitres}Ltr</h4>
                </td>
                <td>
                  <button
                    title="Eliminar"
                    className="btn btn-light"
                    onClick={() => OnDeleteTagContainerHandler(item)}
                  >
                    <FaTrash />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {tagContainerList.length === 0 && <label>No hay informado envases</label>}
    </>
  );
};
