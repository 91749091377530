import * as React from 'react';
import '../../custom.css';
import { Table } from '../helperTable';
import { Column } from 'react-table';
import { Pasos } from '../../model/pasos';
export const PasosTable = (dataState: any) => {
  let data = new Array();
  if (dataState.dataState != undefined) {
    data = dataState.dataState;
  }

  const buttons = [
    {
      btnText: 'Eliminar',
      btnFunc: dataState.eliminarPaso,
      btnclass: 'btn btn-danger'
    }
  ];
  const columns = React.useMemo(
    () =>
      [
        {
          Header: 'Id',
          accessor: 'id',
          isVisible: false
        },

        {
          Header: 'Orden',
          accessor: 'orden'
        },
        {
          Header: 'Materia Prima ID',
          accessor: 'materiaprimaid'
        },
        {
          Header: 'Materia Prima',
          accessor: 'materiaprima.nombre'
        },
        {
          Header: 'Cantidad',
          accessor: 'cantidad'
        },
        {
          Header: 'Pausa',
          accessor: 'pausa'
        },
        {
          Header: 'Minutos de Pausa',
          accessor: 'minutospausa'
        },
        {
          Header: 'Observacion',
          accessor: 'observacion'
        }
      ] as Column<Pasos>[],
    []
  );
  return (
    <React.Fragment>
      <div className="col-md-12 contentpage">
        <Table
          columnas={columns}
          datos={data}
          /*edit={dataState.editPaso}*/ hiddenColumns={[
            'id',
            'materiaprimaid',
            'pausa'
          ]}
          buttons={buttons}
        />
      </div>
    </React.Fragment>
  );
};
