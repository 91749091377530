import * as React from 'react';
import './Layout.css';
import { SideBarComponent } from './SideBar/SideBarComponent';
import { HeaderComponent } from './Header/HeaderComponent';
import { useAuth } from 'react-oidc-context';

export const Layout = (props: { children?: React.ReactNode }) => {

  const auth = useAuth();

  return (
    <div className="layout">
      {auth.isAuthenticated && <SideBarComponent />}
      <div className="layoutContent">
        <HeaderComponent />
        <div className="layoutChildContent">{props.children}</div>
      </div>
    </div>
  );
};

export default Layout;
