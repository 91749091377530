
// Component functions
import { connect } from 'react-redux';
import * as ComponentFunctions from './CancelButton.Funcions';

// component functions
const CancelButtonComponent = (props: ComponentFunctions.Props) => {

    const CancelButtonHandler = (OrderId: number) => {
        props.CancelOrder(OrderId);
    }

    return props.isCanceled ? (
        <h4 className='text-danger'>Orden anulada</h4>) : (
        <button
            className="btn btn-secondary"
            onClick={() => {
                CancelButtonHandler(props.orderId);
            }}
        >
            Anular orden de fabricación
        </button>
    );
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(CancelButtonComponent as any);