import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { User } from '../../../model/authenticate';

import { ApplicationState } from '../../../stores';

// stores
import * as AuthStore from '../../../stores/authentication';

export interface IProps {
  CurrentUser: User | undefined;
  onSaveUser?: (user: User) => void;
  onCancel?: () => void;
}

export const UsersFormComponent = (props: IProps) => {
  const dispatch = useDispatch();

  const [submitted, setSubmitted] = useState<boolean>(false);

  // form fields
  const [id, setId] = useState<string>();
  const [name, setName] = useState<string>();
  const [surname, setSurname] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [PasswordVerification, setPasswordVerification] =
    useState<boolean>(false);

  const [ValidEmail, setValidEmail] = useState<boolean>(false);

  const [formValidationError, setFormValidationError] = useState<string[]>([]);

  // operator state
  const AuthState = useSelector(
    (state: ApplicationState) => state.authentication
  );

  useEffect(() => {
    if (props.CurrentUser) {
      setId(props.CurrentUser.id);
      setName(props.CurrentUser.firstName);
      setEmail(props.CurrentUser.email);
      setSurname(props.CurrentUser.lastName);
    }
  }, []);

  useEffect(() => {
    if (
      submitted &&
      AuthState &&
      (AuthState.isAddingSuccess || AuthState.isSavingSuccess) &&
      AuthState.selectedUser
    ) {
      if (props.onSaveUser) props.onSaveUser(AuthState.selectedUser);
    }
  }, [AuthState?.isAddingSuccess, AuthState?.isSavingSuccess, submitted]);

  // form validation
  const FormValidation = (): boolean => {
    let validationResult: boolean = true;
    let Errors: string[] = [];

    if (!name) {
      Errors = [...Errors, 'Debe informar el nombre del usuario'];
      validationResult = false;
    }

    if (!surname) {
      Errors = [...Errors, 'Debe informar el apellido del usuario'];
      validationResult = false;
    }

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!email) {
      Errors = [...Errors, 'Debe informar el email del usuario'];
      validationResult = false;
    } else {
      if (!pattern.test(email)) {
        validationResult = false;
        Errors = [...Errors, 'el mail ingresado no es valido'];
        setValidEmail(false);
      } else {
        setValidEmail(true);
      }
    }

    if (!props.CurrentUser) {
      if (!password) {
        Errors = [...Errors, 'Debe informar la contraseña del usuario'];
        validationResult = false;
      }

      if (!confirmPassword) {
        Errors = [...Errors, 'confirme la contraseña del usuario'];
        validationResult = false;
      }

      if (password !== confirmPassword) {
        Errors = [...Errors, 'las cotraseñas no coinciden'];
        validationResult = false;
      } else {
        setPasswordVerification(true);
      }
    }

    setFormValidationError(Errors);

    return validationResult;
  };

  // events
  const onCancelFormHandler = () => {
    if (props.onCancel) props.onCancel();
  };

  const onSubmitHandler = () => {
    setSubmitted(true);
    if (FormValidation()) {
      if (id)
        dispatch(
          mapDispatchToProps.SaveUser({
            lastName: surname!,
            firstName: name!,
            userName: email!,
            id: props.CurrentUser?.id!,
            email: email!
          })
        );
      else
        dispatch(
          mapDispatchToProps.AddUser({
            lastName: surname!,
            firstName: name!,
            email: email!,
            userName: email!,
            password: password!,
            confirmPassword: confirmPassword!
          })
        );
    }
  };

  return (
    <>
      <div className="contentpage">
        <div>
          <form className="form">
            <div className="formContent">
              {(AuthState?.FailAdding || AuthState?.FailSaving) &&
                AuthState?.error && (
                  <div className="ErrorContainer">
                    <label>{AuthState?.error?.ErrorMessage}</label>
                    {AuthState?.error?.Errors && (
                      <ul>
                        {AuthState?.error?.Errors.map((error: string) => (
                          <li>{error}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              {formValidationError.length > 0 && (
                <div className="ErrorContainer">
                  <label>Atención</label>
                  <ul>
                    {formValidationError.map((error: string) => (
                      <li>{error}</li>
                    ))}
                  </ul>
                </div>
              )}
              <label className="form-label">
                Nombre <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                title="Código de materia prima"
                className="form-control"
                value={name}
                onChange={(e: any) => setName(e.target.value)}
                maxLength={100}
              />
              {submitted && !surname && (
                <small className="text-danger">Dato requerido</small>
              )}
              <label className="form-label">
                Apellido <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                title="Código de materia prima"
                className="form-control"
                maxLength={100}
                value={surname}
                onChange={(e: any) => setSurname(e.target.value)}
              />
              {submitted && !name && (
                <small className="text-danger">Dato requerido</small>
              )}
              <label className="form-label">
                Mail <span className="text-danger">*</span>
              </label>
              <input
                type="email"
                title="Correo electrónico"
                className="form-control"
                maxLength={150}
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
              />
              {submitted && !email && (
                <small className="text-danger">Dato requerido</small>
              )}
              {submitted && !ValidEmail && (
                <small className="text-danger">El mail no es valido</small>
              )}
              {!props.CurrentUser && (
                <>
                  <label className="form-label">
                    Contraseña <span className="text-danger">*</span>
                  </label>
                  <input
                    type="password"
                    title="Código de materia prima"
                    className="form-control"
                    maxLength={100}
                    value={password}
                    onChange={(e: any) => setPassword(e.target.value)}
                  />
                  {submitted && !password && (
                    <small className="text-danger">Dato requerido</small>
                  )}
                  <label className="form-label">
                    Confirmación de Contraseña{' '}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="password"
                    title="Correo electrónico"
                    className="form-control"
                    maxLength={150}
                    value={confirmPassword}
                    onChange={(e: any) => setConfirmPassword(e.target.value)}
                  />
                  {submitted && !email && (
                    <small className="text-danger">Dato requerido</small>
                  )}
                  {submitted && !PasswordVerification && (
                    <small className="text-danger">
                      Las contraseñas no coinciden
                    </small>
                  )}
                </>
              )}
              {!props.CurrentUser && (
                <label className="mt-3 text-info">
                  <h6>
                    <strong>Importante:</strong>
                  </h6>
                  <small className="text-info">
                    La contraseña debe tener más de 6 caracteres, mayúsculas y
                    minúsculas y números y al menos un caracter no alfanumérico
                  </small>
                </label>
              )}
            </div>
            <div className="formButtons">
              {AuthState && !AuthState.isAdding && !AuthState?.isSaving ? (
                <>
                  <button
                    type="button"
                    onClick={onSubmitHandler}
                    className="btn btn-primary"
                  >
                    Confirmar
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={onCancelFormHandler}
                  >
                    Cancelar
                  </button>
                </>
              ) : (
                <>
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Cargando...</span>
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.Operators
});

const mapDispatchToProps = {
  ...AuthStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersFormComponent as any);
