import { ApplicationState } from '../../../stores';
import * as StockStore from '../../../stores/Stock';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { MeasurementUnit } from '../../../model/MeasurementUnit';

export interface IProps {
  CurrentStock: number;
  StockMeasurmentUnit: MeasurementUnit;
  MinimumStockAllowed: number | null;
  StockId: number;
  onSolicitateChangeStockAmount?: () => void;
}

export const WidgetStock = (props: IProps) => {
  const StockState = useSelector((state: ApplicationState) => state.Stock);

  const ChangeStockAmountClickHandler = () => {
    if (props.onSolicitateChangeStockAmount)
      props.onSolicitateChangeStockAmount();
  };

  return (
    <>
      <label>Stock actual</label>
      <h3
        className={
          props.MinimumStockAllowed &&
          props.MinimumStockAllowed > props.CurrentStock
            ? 'text-danger'
            : 'text-success'
        }
      >
        {props.CurrentStock} {props.StockMeasurmentUnit.codigo}&nbsp;
        {props.onSolicitateChangeStockAmount && (
          <button
            type="button"
            className="btn btn-primary"
            onClick={ChangeStockAmountClickHandler}
          >
            Modificar
          </button>
        )}
      </h3>
      <span className="badge bg-secondary">
        {props.MinimumStockAllowed
          ? 'Mínimo definido: ' +
            props.MinimumStockAllowed +
            ' ' +
            props.StockMeasurmentUnit.codigo
          : 'No se ha definido stock mínimo'}
      </span>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.Stock
});

const mapDispatchToProps = {
  ...StockStore.actionCreators
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetStock as any);
