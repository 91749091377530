import { AppThunkAction } from '../../stores';
import { ErrorType } from '../../model/FetchError';

// services
import * as Services from '../../services/RemitosServices';
import * as SuppliesServices from '../../services/SuppliesServices';

// auth
import { User } from "oidc-client-ts"

// actions
import * as Actions from '../Actions/RemitoActions';
import * as SuppliesActions from '../Actions/SuppliesActions';


type KnownAction =
    | Actions.Remito_GetAll_Request_Action
    | Actions.Remito_GetAll_Success_Action
    | Actions.Remito_GetAll_Fail_Action
    | Actions.Remito_Create_Request_Action
    | Actions.Remito_Create_Success_Action
    | Actions.Remito_Create_Fail_Action
    | Actions.Remito_Get_Request_Action
    | Actions.Remito_Get_Success_Action
    | Actions.Remito_Get_Fail_Action
    | Actions.Remito_Update_Request_Action
    | Actions.Remito_Update_Success_Action
    | Actions.Remito_Update_Fail_Action
    | Actions.Remito_Delete_Request_Action
    | Actions.Remito_Delete_Success_Action
    | Actions.Remito_Delete_Fail_Action
    | SuppliesActions.ReceiveSupply;


    function getUser() {
        const oidcStorage = sessionStorage.getItem(`oidc.user:https://keycloak.infra.idbnar.com/realms/Expocolor/:` + process.env.REACT_APP_CLIENT_ID);
        console.log("info user", oidcStorage);
        if (!oidcStorage) {
            return null;
        }
    
        return User.fromStorageString(oidcStorage);
    }


export const actionCreators = {
    GetAll: (
        pageNumber?: string,
        pageSize?: string,
        text?: string,
        filterOn?: string //Parametro para filtrado;
    ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        
        const user = getUser();
        const token = user?.access_token;

        if (!token) {
            dispatch({
                type: 'REMITO_GETALL_FAIL',
                Error: {
                    ErrorCode: 401, 
                    ErrorMessage: 'No se ha podido obtener el token de autenticación',
                    Errors: []
                }
            })
            return;
        }

        dispatch({
            type: 'REMITO_GETALL_REQUEST',
            isFiltered: text?.length ? true : false,
            filterCriteria: text
        });

        Services.GetAllRemitos(token, pageNumber, pageSize, text, filterOn)
            .then((remitos) => {
                dispatch({
                    type: 'REMITO_GETALL_SUCCESS',
                    remitos: remitos
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'REMITO_GETALL_FAIL',
                    Error: error
                })
            );

        },
    Create: (newRemito: any): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            const user = getUser();
            const token = user?.access_token;

            if (!token) {
                dispatch({
                    type: 'REMITO_CREATE_FAIL',
                    Error: {
                        ErrorCode: 401, 
                        ErrorMessage: 'No se ha podido obtener el token de autenticación',
                        Errors: []
                    }
                })
                return;
            }

            dispatch({
                type: 'REMITO_CREATE_REQUEST',
                newRemito: newRemito
            });

            Services.CreateRemito(newRemito, token)
                .then((remito) => {
                    dispatch({
                        type: 'REMITO_CREATE_SUCCESS',
                        remito: remito
                    });
                    SuppliesServices.GetSupplyById(remito.materiaPrima.id, token).then((supply) => {
                        dispatch({
                            type: 'RECEIVE_SUPPLY',
                            supply: supply
                            });
                        }
                    );

                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'REMITO_CREATE_FAIL',
                        Error: error
                    })
                );

        },
    Get: (remitoId: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {

            var state = getState();

            const user = getUser();
            const token = user?.access_token;

            if (!token) {
                dispatch({
                    type: 'REMITO_GET_FAIL',
                    Error: {
                        ErrorCode: 401, 
                        ErrorMessage: 'No se ha podido obtener el token de autenticación',
                        Errors: []
                    }
                })
                return;
            }

            if(state.remitos?.list?.find(x => x.id === remitoId) === undefined) {

                dispatch({
                    type: 'REMITO_GET_REQUEST',
                    remitoId: remitoId
                });
    
                Services.GetRemitoById(remitoId, token)
                .then((remito) => {
                    dispatch({
                        type: 'REMITO_GET_SUCCESS',
                        remito: remito
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'REMITO_GET_FAIL',
                        Error: error
                    })
                );

            }
        },
    Update: (remito: any): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            var state = getState();

            const user = getUser();
            const token = user?.access_token;

            if (!token) {
                dispatch({
                    type: 'REMITO_UPDATE_FAIL',
                    Error: {
                        ErrorCode: 401, 
                        ErrorMessage: 'No se ha podido obtener el token de autenticación',
                        Errors: []
                    }
                })
                return;
            }

            dispatch({
                type: 'REMITO_UPDATE_REQUEST',
                remito: remito
            });

            Services.UpdateRemito(remito, token)
                .then((remito) => {
                    dispatch({
                        type: 'REMITO_UPDATE_SUCCESS',
                        remito: remito
                    });
                    SuppliesServices.GetSupplyById(remito.materiaPrima.id, token).then((supply) => {
                        dispatch({
                            type: 'RECEIVE_SUPPLY',
                            supply: supply
                            });
                        }
                    );
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'REMITO_UPDATE_FAIL',
                        Error: error
                    })
                );

        },
    Delete: (remitoId: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            var state = getState();

            const user = getUser();
            const token = user?.access_token;

            if (!token) {
                dispatch({
                    type: 'REMITO_DELETE_FAIL',
                    Error: {
                        ErrorCode: 401, 
                        ErrorMessage: 'No se ha podido obtener el token de autenticación',
                        Errors: []
                    }
                })
                return;
            }


            dispatch({
                type: 'REMITO_DELETE_REQUEST',
                remitoId: remitoId
            });

            Services.DeleteRemito(remitoId, token)
                .then(() => {
                    dispatch({
                        type: 'REMITO_DELETE_SUCCESS',
                        remitoId: remitoId
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'REMITO_DELETE_FAIL',
                        Error: error
                    })
                );
        }
};