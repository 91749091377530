import { AppThunkAction } from '../../stores';
import { ErrorType } from '../../model/FetchError';

// services
import * as Services from '../../services/SuppliesServices';

// auth
import { User } from "oidc-client-ts"


// actions
import * as Actions from '../Actions/SuppliesActions';
import { MateriaPrima, NewMateriaPrima } from '../../model/materia_prima';


type KnownAction =
    | Actions.RequestAllSupplies
    | Actions.ReceiveAllSupplies
    | Actions.RequestAllLowStockSupplies
    | Actions.ReceiveAllLowStockSupplies
    | Actions.RequestSupply
    | Actions.ReceiveSupply
    | Actions.SetSelectedSupply
    | Actions.UnsetSelectedSupply
    | Actions.RequestAddSupply
    | Actions.ReceiveAddedSupply
    | Actions.RequestSaveSupply
    | Actions.ReceiveSavedSupply
    | Actions.RequestDeleteSupply
    | Actions.ReceiveDeletedSupply
    | Actions.FailGetAllSupplies
    | Actions.FailGetSupply
    | Actions.FailAddSupply
    | Actions.FailSaveSupply
    | Actions.FailDeleteSupply;


    function getUser() {
        const oidcStorage = sessionStorage.getItem(`oidc.user:https://keycloak.infra.idbnar.com/realms/Expocolor/:` + process.env.REACT_APP_CLIENT_ID);
        console.log("info user", oidcStorage);
        if (!oidcStorage) {
            return null;
        }
    
        return User.fromStorageString(oidcStorage);
    }

export const actionCreators = {
    GetAllSupplies: (
        pageNumber?: number,
        pageSize?: number,
        text?: string
    ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        var state = getState();

        const user = getUser();
        const token = user?.access_token;
        
        if (!token) {
            dispatch({
                type: 'FAIL_GET_ALL_SUPPLIES',
                Error: {
                    ErrorCode: 401, 
                    ErrorMessage: 'No se ha podido obtener el token de autenticación',
                    Errors: []
                 }
            })
            return;
        }

        if(!state.Supplies?.isLoadingAllSuccess && !state.Supplies?.RawMaterials?.length ) {
            dispatch({
                type: 'REQUEST_ALL_SUPPLIES',
                filterCriteria: text?.length ? text : undefined
            });
    
            Services.GetAllSupplies(token, pageNumber, pageSize, text)
                .then((supplies) => {
                    dispatch({
                        type: 'RECEIVE_ALL_SUPPLIES',
                        isFiltered: text?.length ? true : false,
                        filterCriteria: text,
                        supplies: supplies
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'FAIL_GET_ALL_SUPPLIES',
                        Error: error
                    })
                );
        }
    },
    GetAllLowStockSupplies: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        const user = getUser();
        const token = user?.access_token;

        if (!token) {
            dispatch({
                type: 'FAIL_GET_ALL_SUPPLIES',
                Error: {
                    ErrorCode: 401, 
                    ErrorMessage: 'No se ha podido obtener el token de autenticación',
                    Errors: []
                 }
            });
            return;
        }

        dispatch({
            type: 'REQUEST_ALL_LOW_STOCK_SUPPLIES'
        });

        Services.GetLowStockSupplies(token!)
            .then((supplies) => {
                dispatch({
                    type: 'RECEIVE_ALL_LOW_STOCK_SUPPLIES',
                    supplies: supplies
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'FAIL_GET_ALL_SUPPLIES',
                    Error: error
                })
            );

        },
    GetSupplyById: (Id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        const user = getUser();
        const token = user?.access_token;

        if (!token) {
            dispatch({
                type: 'FAIL_GET_SUPPLY',
                Error: {
                    ErrorCode: 401, 
                    ErrorMessage: 'No se ha podido obtener el token de autenticación',
                    Errors: []
                 }
            })
            return;
        }

        dispatch({
            type: 'REQUEST_SUPPLY',
            Id: Id
        });

        Services.GetSupplyById(Id, token!)
            .then((supply) => {
                dispatch({
                    type: 'RECEIVE_SUPPLY',
                    supply: supply
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'FAIL_GET_SUPPLY',
                    Error: error
                })
            );

        },
    CreateSupply: (supply: NewMateriaPrima): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const user = getUser();
        const token = user?.access_token;

        if (!token) {
            dispatch({
                type: 'FAIL_ADD_SUPPLY',
                Error: {
                    ErrorCode: 401, 
                    ErrorMessage: 'No se ha podido obtener el token de autenticación',
                    Errors: []
                 }
            })
            return;
        }

        dispatch({
            type: 'REQUEST_ADD_SUPPLY',
            newSupply: supply
        });

        Services.CreateSupply(supply, token)
            .then((supply) => {
                dispatch({
                    type: 'RECEIVE_ADDED_SUPPLY',
                    newSupply: supply
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'FAIL_ADD_SUPPLY',
                    Error: error
                })
            );

        },
    UpdateSupply: (supply: MateriaPrima): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const user = getUser();
        const token = user?.access_token;

        if (!token) {
            dispatch({
                type: 'FAIL_SAVE_SUPPLY',
                Error: {
                    ErrorCode: 401, 
                    ErrorMessage: 'No se ha podido obtener el token de autenticación',
                    Errors: []
                 }
            })
            return;
        }

        dispatch({
            type: 'REQUEST_SAVE_SUPPLY',
            supply: supply
        });

        Services.UpdateSupply(supply, token)
            .then((supply) => {
                dispatch({
                    type: 'RECEIVE_SAVED_SUPPLY',
                    savedSupply: supply
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'FAIL_SAVE_SUPPLY',
                    Error: error
                })
            );

        },
    Delete: (supplyId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const user = getUser();
        const token = user?.access_token;

        if (!token) {
            dispatch({
                type: 'FAIL_DELETE_SUPPLY',
                Error: {
                    ErrorCode: 401, 
                    ErrorMessage: 'No se ha podido obtener el token de autenticación',
                    Errors: []
                 }
            })
            return;
        }

        dispatch({
            type: 'REQUEST_DELETE_SUPPLY',
            SupplyId: supplyId
        });

        Services.DeleteSupply(supplyId, token)
            .then(() => {
                dispatch({
                    type: 'RECEIVE_DELETED_SUPPLY',
                    SupplyId: supplyId
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'FAIL_DELETE_SUPPLY',
                    Error: error
                })
            );

        }
};