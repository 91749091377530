import { ErrorType } from '../../../model/FetchError';
import { ApplicationState } from '../../../stores';

//stores 
import * as RemitoStore from '../../../redux/Middleware/RemitoMiddleware';
import { Remito } from '../../../model/Remito/Remito';


//#region Props

interface IProps {}

interface ConnectedProps {
    isLoading: boolean | undefined;
    LoadingSuccessfully: boolean | undefined;
    failOnLoading: boolean | undefined;
    failOnDelete: boolean | undefined;
    error: ErrorType | undefined;
    remitos: Remito[] | undefined;
    isFilteres: boolean | undefined;
    filterCriteria: string | undefined;
}

interface MedicalServicesDispatchProps {
    GetAll: typeof RemitoStore.actionCreators.GetAll;
    Delete: typeof RemitoStore.actionCreators.Delete;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: ApplicationState, ownProps: IProps) => ({
    isLoading: state.remitos?.isLoadingAll,
    LoadingSuccessfully: state.remitos?.isLoadingAllSuccess,
    failOnLoading: state.remitos?.FailGettingAll,
    failOnDelete: state.remitos?.FailDeletingOne,
    error: state.remitos?.Error,
    remitos: state.remitos?.list || [],
    isFilteres: state.remitos?.isFiltered,
    filterCriteria: state.remitos?.filterCriteria
});

export const mapDispatchToProps = {
    ...RemitoStore.actionCreators
};

//#endregion Props