import { useEffect, useState } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import { ActionMeta, SingleValue } from 'react-select';
import Select from 'react-select';

// Stores
import * as OperatorStore from '../../../stores/Operators';
import { Operator } from '../../../model/operator';
import { ApplicationState } from '../../../stores';

export interface IProps {
  InitialSelection: number | null;
  onOperatorSelectedEvent?: (operator: Operator) => void;
}

export const OperatorSelectorComponent = (props: IProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(mapDispatchToProps.GetOperators());
  }, []);

  useEffect(() => {
    if (props.InitialSelection !== null)
      setOperatorSelected(
        OperatorState?.Operators.find(
          (operator: Operator) => operator.id === props.InitialSelection
        ) || null
      );
  }, [props.InitialSelection]);

  const OperatorState = useSelector(
    (state: ApplicationState) => state.Operators
  );

  const [operatorSelected, setOperatorSelected] = useState<Operator | null>(
    null
  );

  const changeSelectionHandler = (
    newValue: SingleValue<Operator>,
    actionMeta: ActionMeta<Operator>
  ) => {
    setOperatorSelected(newValue);
    if (props.onOperatorSelectedEvent && newValue)
      props.onOperatorSelectedEvent(newValue);
  };

  return (
    <Select
      options={OperatorState?.Operators}
      value={operatorSelected}
      placeholder="Seleccione..."
      getOptionLabel={(operator: Operator) =>
        operator.apellido + ', ' + operator.nombre
      }
      getOptionValue={(operator: Operator) => operator.id.toString()}
      onChange={changeSelectionHandler}
    />
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.Operators
});

const mapDispatchToProps = {
  ...OperatorStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OperatorSelectorComponent as any);
