import { Operator } from '../../model/operator';
import { CommonState } from '../../stores/common/commonState';

export interface OperatorState extends CommonState {
  Operators: Operator[];
  SelectedOperator: Operator | undefined;
}

export const unloadedState: OperatorState = {
  isLoadingAll: false,
  isLoadingAllSuccess: undefined,
  isLoadingOne: false,
  isLoadingOneSuccess: undefined,
  isSaving: false,
  isSavingSuccess: undefined,
  isAdding: false,
  isAddingSuccess: undefined,
  FailGettingAll: false,
  FailAdding: false,
  FailSaving: false,
  FailGettingOne: false,
  isFiltered: false,
  filterCriteria: undefined,
  Operators: [],
  SelectedOperator: undefined,
  Error: undefined,
  isDeletingOne: false,
  isDeletingOneSuccess: undefined,
  FailDeletingOne: false
};
