import { AppThunkAction } from '../../stores';

// services
import * as Services from '../../services/OrderServices';

// auth
import { User } from "oidc-client-ts"


// actions
import * as Actions from '../Actions/OrderActions';
import { ErrorType } from '../../model/FetchError';


type KnownAction =
    | Actions.Order_Cancel_Request_Action
    | Actions.Order_Cancel_Success_Action
    | Actions.Order_Cancel_Fail_Action;


    function getUser() {
        const oidcStorage = sessionStorage.getItem(`oidc.user:https://keycloak.infra.idbnar.com/realms/Expocolor/:` + process.env.REACT_APP_CLIENT_ID);
        console.log("info user", oidcStorage);
        if (!oidcStorage) {
            return null;
        }
    
        return User.fromStorageString(oidcStorage);
    }

export const actionCreators = {
    CancelOrder:
        (orderId: number): AppThunkAction<KnownAction> =>
            async (dispatch, getState) => {

                const user = getUser();
                const token = user?.access_token;

                if (!token) {
                    dispatch({
                        type: 'ORDER_CANCEL_FAIL',
                        Error: {
                            ErrorCode: 401, 
                            ErrorMessage: 'No se ha podido obtener el token de autenticación',
                            Errors: []
                        }
                    })
                    return;
                }

                dispatch({
                    type: 'ORDER_CANCEL_REQUEST',
                    orderId: orderId
                });

                Services.CancelOrder(orderId, token)
                    .then(() => {
                        dispatch({
                            type: 'ORDER_CANCEL_SUCCESS',
                            orderId: orderId
                        });
                    })
                    .catch((error: ErrorType) =>
                        dispatch({
                            type: 'ORDER_CANCEL_FAIL',
                            Error: error
                        })
                    );

            }
};
