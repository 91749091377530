import { AdditionalRawMaterial } from '../../../../model/AdditionalRawMaterial';

export interface IProps {
  RawMaterial: AdditionalRawMaterial[];
}

export const OrdenFabricacionAdicionalesComponent = (props: IProps) => {
  return (
    <>
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Materia prima</th>
            <th>Cantidad</th>
          </tr>
        </thead>
        <tbody>
          {props.RawMaterial.map((supply: AdditionalRawMaterial) => (
            <tr>
              <td>
                {supply.materiaprima
                  ? supply.materiaprima.codigo +
                    ' | ' +
                    supply.materiaprima.nombre
                  : 'Materia prima no encontrada'}
              </td>
              <td>
                {supply.materiaprima
                  ? supply.cantidad + supply.materiaprima.unidadDeMedida.codigo
                  : 'Materia prima no encontrada'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {props.RawMaterial.length === 0 && (
        <label>No se han utiliado materiales adicionales</label>
      )}
    </>
  );
};
