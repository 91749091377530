import * as React from 'react';
import { useState, useEffect } from 'react'; // Se importa useState
import '../../custom.css';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import { Producto } from '../../model/producto';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../stores';
import * as OrdenStore from '../../stores/OrdenFabricacion';
import * as ProductoStore from '../../stores/Productos';
import { Orden } from '../../model/orden';
import { PasosFormOrden, selectFormulaState } from '../formula/pasosFormOrden';
import { SaveOrden } from './saveOrden';
import Select from 'react-select';
import { MateriaPrima } from '../../model/materia_prima';

//styles
import './orden.css';

export interface IProps {
  productos: Producto[] | undefined;
  materiaPrimas: MateriaPrima[] | undefined;
  onAddedOrder: () => void;
}

export const OrdenForm = (props: IProps) => {
  const dispatch = useDispatch();
  const [productoError, setProductoError] = React.useState(false);

  const [loader, setLoader] = React.useState(false);

  const [pasosValid, setPasosValid] = useState(false); // Estado para la validación de los pasos;
  const [formValid, setFormValid] = useState(false); // Estado para la validación del formulario;
  const [formAndPasosValid, setFormAndPasosValid] = useState(false); // Estado para la validación del formulario y Pasos para activar el boton 'Generar orden de fabricación';

  const productos: Producto[] = props.productos ? props.productos! : [];
  const OrdenState = useSelector((state: ApplicationState) => state.ordenes);
  const PasosState = useSelector((state: ApplicationState) => state.formulas);
    
  const [lote, setLote] = React.useState<number>();
  const [selectedOption, setSelectedOption] = React.useState<number>();
  const [cantidad, setCantidad] = React.useState<number>();
  const [cantidadFabricacion, setCantidadFabricacion] =
    React.useState<number>();
  const [litrosEsperados, setLitrosEsperados] = React.useState<number>();
  const [comentariosIniciales, setComentariosIniciales] =
    React.useState<string>();
  const [codigoCliente, setCodigoCliente] = React.useState<string>('');

  const [ComponentsErrors, setComponentsErrors] = React.useState<string[]>([]);
  const [isSaving, setisSaving] = React.useState<boolean>(false);

  const formulaState = useSelector(selectFormulaState);

  const ProductoState = useSelector(
    (state: ApplicationState) => state.productos
  );

  useEffect(() => {
    if (selectedOption && cantidad) {
      dispatch(mapDispatchToProps.getFormulaKilo(selectedOption, cantidad));
    }
  }, [selectedOption, cantidad]);

  useEffect(() => {
    // Función de validación de formulario;
    const validateForm = () => {
      const formIsValid = (codigoCliente.trim() !== '' && cantidad && litrosEsperados && cantidadFabricacion);

      if ( formIsValid ) {
        setFormValid(true);
      } else {
        setFormValid(false);
      }

    };
    validateForm();
  }, [codigoCliente, cantidad, litrosEsperados, cantidadFabricacion]);

  useEffect(() => {
    // Función de validación de pasos
    const validatePasos = () => {

      let pasosLength = 0;
      if (formulaState?.pasos) {
        pasosLength = formulaState.pasos.length;
      }
      
      if (pasosLength > 0 && ComponentsErrors.length == 0 ) {
        setPasosValid(true);
      } else {
        setPasosValid(false);
      }
      
    };
    validatePasos();
  }, [formulaState?.pasos, ComponentsErrors]);  

  useEffect(() => {
    // Validación para Habilitar o deshabilitar el botón 'Generar orden de fabricación';
    if (formValid && pasosValid && ComponentsErrors.length === 0) {
      setFormAndPasosValid(true);
    }else{
      setFormAndPasosValid(false);
    }
  }, [formValid, pasosValid]);

  const ChangeProductHandler = (e: any) => {
    if (e) setSelectedOption(e.id);
  };

  const ChangeKilogramosHandler = (e: any) => {
    if (e) setCantidad(Number(e.target.value));
  };

  const ChangeLitrosEsperadosHandler = (e: any) => {
    if (e) setLitrosEsperados(Number(e.target.value));
  };

  const ChangeComentariosInicialesHandler = (e: any) => {
    if (e) setComentariosIniciales(e.target.value);
  };

  const ChangeFabricacionesHandler = (e: any) => {
    if (e) setCantidadFabricacion(Number(e.target.value));
  };

  const ChangeCodigoClienteHandler = (e: any) => {
    if (e) setCodigoCliente(e.target.value);
  };

  const onSubmit = (data: Orden) => {
    setProductoError(false);
    if (selectedOption === undefined) {
      setProductoError(true);
      return;
    }
    setLoader(true);
    dispatch(mapDispatchToProps.getFormulaKilo(selectedOption, data.cantidad));
  };

  const onSave = () => {
    
    if(!formValid) return; // Verifica si el formulrio es válido antes de guardar;

    if (selectedOption !== undefined && codigoCliente !== undefined) {
      setisSaving(true);
      var newOrden: SaveOrden = {
        productoid: selectedOption,
        formula: {
          id: undefined,
          lista_elementos: PasosState?.pasos ? PasosState?.pasos : [],
          productoid: selectedOption,
          observacion: ''
        },
        litros_esperados: litrosEsperados!,
        comentariosIniciales: comentariosIniciales!,
        fechaorden: new Date(),
        cantidad: cantidad ? cantidad! : 0,
        cantidadFabricaciones: cantidadFabricacion ? cantidadFabricacion! : 1,
        lote: lote ? lote! : 0,
        codigoCliente: codigoCliente!
      };

      dispatch(mapDispatchToProps.saveOrden(newOrden));
      props.onAddedOrder();
    }
  };

  const OnHandleErrorFormulaComponents = (Errors: string[]) => {
    setComponentsErrors(Errors);
  };

  return (
    <>
      <div className="ordenForm">
        <form>
          <div className="col-md-12 d-flex align-items-center">
            <div className="col-md-3 mb-2">
              <Form.Group controlId="formBasicSelect">
                <Form.Label>Productos</Form.Label>
                <Select
                  options={productos}
                  isDisabled={loader}
                  placeholder="Seleccione..."
                  getOptionLabel={(productos) =>
                    productos.codigo + ' - ' + productos.nombre
                  }
                  value={productos.filter(
                    (option) => option.id === selectedOption
                  )}
                  getOptionValue={(productos) => productos.id.toString()}
                  onChange={ChangeProductHandler}
                />
              </Form.Group>
            </div>
            <div className="col-md-2 mb-2 ms-2">
              <Form.Label>Código Cliente</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                onChange={ChangeCodigoClienteHandler}
              />
            </div>
            <div className="col-md-2 mb-2 ms-2">
              <Form.Label>Kilogramos</Form.Label>
              <Form.Control
                type="number"
                placeholder="kg."
                min="1"
                className="form-control"
                onChange={ChangeKilogramosHandler}
              />
            </div>
            <div className="col-md-2 mb-2 ms-2">
              <Form.Label>Litros esperados</Form.Label>
              <Form.Control
                type="number"
                placeholder="Lts."
                min="1"
                step="1"
                className="form-control"
                onChange={ChangeLitrosEsperadosHandler}
              />
            </div>
            <div className="col-md-2 mb-2 ms-2">
              <Form.Label>Fabricaciones</Form.Label>
              <Form.Control
                type="number"
                placeholder="Cantidad"
                min="1"
                step="1"
                max="99"
                className="form-control"
                onChange={ChangeFabricacionesHandler}
              />
            </div>
            {/* <div className="col-md-2 mb-2 ms-2">
              <Form.Label>Lote</Form.Label>
              <Form.Control
                type="number"
                placeholder="Lote"
                min="1"
                className="form-control"
                onChange={ChangeLoteHandler}
              />
            </div> */}
          </div>
        </form>
        {/* {ComponentsErrors.length > 0 && (
          <div className="ErrorContainer">
            <ul>
              {ComponentsErrors.map((error: string) => (
                <li>{error}</li>
              ))}
            </ul>
          </div>
        )} */}
        <hr />
        {ProductoState &&
        ProductoState.producto &&
        ProductoState.producto.formula ? (
          <PasosFormOrden
            FormulaId={ProductoState.producto.formula.id!}
            byAverage={false}
            ProductAmount={cantidad}
            OnErrorEvent={OnHandleErrorFormulaComponents}
          />
        ) : null}
        <div className="col-md-12 mr-2">
          <Form.Label>Comentarios</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            type="text"
            placeholder="Comentarios"
            className="form-control"
            onChange={ChangeComentariosInicialesHandler}
          />
        </div>
        <div className="ordenFormFooter">
          {!isSaving ? (
            <button
            type="button"
            className={
              ComponentsErrors.length === 0 && formAndPasosValid // Aplica estilo según si el formulario y los 'pasos' son válido
                ? 'btn btn-primary'
                : 'btn btn-secondary'
            }
            disabled={!formAndPasosValid } // Deshabilita el botón si el formulario no es válido y si los 'pasos' no son válido;
            onClick={onSave}
          >
              Generar orden de fabricación
            </button>
          ) : (
            <>
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Generando orden...</span>
              </div>
              {'  '}&nbsp;&nbsp;Generando orden....
            </>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.ordenes
});

const mapDispatchToProps = {
  ...OrdenStore.actionCreators,
  ...ProductoStore.actionCreators
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdenForm as any);
