import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ActionMeta, SingleValue } from 'react-select';
import Select from 'react-select';
import { MateriaPrima } from '../../../model/materia_prima';
import { ApplicationState } from '../../../stores';

//stores
import * as SuppliesStore from '../../../stores/Materia_Prima';

//styles
import './OrderAdditionalComponent.css';
import { FaPlus } from 'react-icons/fa';
import { AdditionalRawMaterial } from '../../../model/AdditionalRawMaterial';

export interface IProps {
  OrderId: number;
  SaveOnSubmit: boolean;
  OnAddComponentEvent?: (additionalRawMaterial: AdditionalRawMaterial) => void;
  AllReadyAddedComponents?: AdditionalRawMaterial[];
}

export const AdditionalComponentForm = (props: IProps) => {
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const SuppliesState = useSelector(
    (state: ApplicationState) => state.Supplies
  );

  const dispatch = useDispatch();

  // form fields
  const [rawMateriaSelected, setRawMateriaSelected] = useState<MateriaPrima>();
  const [cantidad, setCantidad] = useState<number>();

  useEffect(() => {
    dispatch(mapDispatchToProps.GetAllSupplies(0, 5000));
  }, [dispatch]);

  const ValidateForm = (): boolean => {
    let validationErrors: string[] = [];
    if (!rawMateriaSelected) {
      validationErrors = [
        ...validationErrors,
        'Debe seleccionar una materia prima'
      ];
    }

    if (!cantidad) {
      validationErrors = [
        ...validationErrors,
        'Debe informar una cantida de materia prima utilizada'
      ];
    }

    // duplication validate in case that AllReadyAddedComponents props was passed
    if (props.AllReadyAddedComponents) {
      const AdditionalRawMaterialSelected = props.AllReadyAddedComponents.find(
        (rawMaterialAlreadyAdded: AdditionalRawMaterial) =>
          rawMaterialAlreadyAdded.materiaprimaid === rawMateriaSelected?.id
            ? true
            : false
      );

      if (AdditionalRawMaterialSelected)
        validationErrors = [
          ...validationErrors,
          'El material adicional ya fue agregado'
        ];
    }

    setErrors(validationErrors);

    if (validationErrors.length > 0) return false;
    else return true;
  };

  const ResetForm = () => {
    setFormSubmitted(false);
    setCantidad(undefined);
    setErrors([]);
    setRawMateriaSelected(undefined);
  };

  // fields changes handlers
  const OnFormSubmitHandler = () => {
    setFormSubmitted(true);
    if (ValidateForm()) {
      props.OnAddComponentEvent &&
        props.OnAddComponentEvent({
          cantidad: cantidad!,
          materiaprimaid: rawMateriaSelected!.id,
          materiaprima: rawMateriaSelected,
          observacion: '',
          ordenfabricacionid: props.OrderId
        });
      ResetForm();
    }
  };

  return (
    <>
      {errors.length > 0 && (
        <div className="ErrorContainer">
          <ul>
            {errors.map((error: string) => (
              <li>{error}</li>
            ))}
          </ul>
        </div>
      )}
      <form className="formOrderAdditionalComponents">
        <div>
          <label className="form-label">
            Materia prima <span className="text-danger">*</span>
          </label>
          <Select
            className={
              formSubmitted && rawMateriaSelected === undefined
                ? 'textFieldError'
                : ''
            }
            value={SuppliesState?.RawMaterials?.filter(
              (rawMateria: MateriaPrima) =>
                rawMateria.id === rawMateriaSelected?.id ? true : false
            )}
            options={SuppliesState?.RawMaterials}
            placeholder="Seleccione..."
            getOptionLabel={(rawMateriaItem: MateriaPrima) =>
              rawMateriaItem.codigo && rawMateriaItem.nombre
                ? rawMateriaItem.codigo + ' - ' + rawMateriaItem.nombre
                : rawMateriaItem.codigo
            }
            getOptionValue={(rawMateria: MateriaPrima) =>
              rawMateria.id.toString()
            }
            onChange={(
              newValue: SingleValue<MateriaPrima>,
              actionMeta: ActionMeta<MateriaPrima>
            ) => setRawMateriaSelected(newValue! as MateriaPrima)}
          />
          {formSubmitted && rawMateriaSelected === undefined && (
            <small className="text-danger">Dato requerido</small>
          )}
        </div>
        <div>
          <label className="form-label">
            Kilogramos <span className="text-danger">*</span>
          </label>
          <input
            type="number"
            title="Kilogramos a utilizar del producto"
            min="0.001"
            className={
              formSubmitted && cantidad === undefined
                ? 'form-control textFieldError'
                : 'form-control'
            }
            step=".001"
            value={cantidad || ''}
            onChange={(event: any) => setCantidad(Number(event.target.value))}
          />
          {formSubmitted && cantidad === undefined && (
            <small className="text-danger">Dato requerido</small>
          )}
        </div>
        <div className="formButtons baseBottom">
          <button
            type="button"
            className="btn btn-primary"
            onClick={OnFormSubmitHandler}
            title="Agregar"
          >
            <FaPlus />
          </button>
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.Supplies
});

const mapDispatchToProps = {
  ...SuppliesStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdditionalComponentForm as any);
