import { IResult } from '../model/Result';
import { StockTransaction } from '../model/Stock';

// Functions
import * as Functions from '../redux/Functions/Commons';

const BaseURL = process.env.REACT_APP_API_ENDPOINT + 'v1/stocks';

export const GetStockMovements = async (
  stockId: number
): Promise<StockTransaction[]> => {
  const RequestURL: string = BaseURL + '/stocks/' + stockId + '/movimientos?PageNumber=1&PageSize=150';

  try {
    const Response: StockTransaction[] = await fetch(RequestURL)
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<StockTransaction[]>>;
        }
      })
      .then((data: IResult<StockTransaction[]>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    throw error;
  }
};
