import { ErrorType } from './../model/FetchError';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { AuthenticatedUser, NewUser, User } from '../model/authenticate';

// services
import * as AuthServices from '../services/AuthenticationServices';

// functions
import * as Functions from '../redux/Functions/Commons';

var jwt = require('jsonwebtoken');

// Authentication Actions
interface AuthenticateAction {
  type: 'AUTHENTICATE';
  user: string;
}

interface RenewTokenAction {
  type: 'RENEW_TOKEN';
  Token: string;
}

interface setNewTokenAction {
  type: 'SET_NEW_TOKEN';
  newToken: AuthenticatedUser;
}

interface AuthenticatedAction {
  type: 'AUTHENTICATED';
  AuthenticatedUser: AuthenticatedUser;
}

interface failAuthenticationAction {
  type: 'FAIL_AUTHENTICATION';
  Error: ErrorType;
}

// Users Action
interface RequestUsersAction {
  type: 'REQUEST_USERS_ACTION';
}

interface LoginOutAction {
  type: 'LOGIN_OUT_ACTION';
}
interface ReceiveUsersAction {
  type: 'RECEIVE_USERS_ACTION';
  users: User[];
}
interface failGettingUsersAction {
  type: 'FAIL_GETTING_USERS_ACTION';
  Error: ErrorType;
}

// Add User
export interface RequestAddUserAction {
  type: 'REQUEST_ADD_USER';
  newUser: NewUser;
}
export interface ReceiveAddedUserAction {
  type: 'RECEIVE_ADDED_USER';
  user: User;
}
export interface FailAddUserAction {
  type: 'FAIL_ADD_USER';
  Error: ErrorType;
}

// update user
export interface RequestSaveUserAction {
  type: 'REQUEST_SAVE_USER';
  user: User;
}
export interface ReceiveSavedUserAction {
  type: 'RECEIVE_SAVED_USER';
  user: User;
}
export interface FailSaveUserAction {
  type: 'FAIL_SAVE_USER';
  Error: ErrorType;
}

export interface RequestChangePasswordUserAction {
  type: 'REQUEST_CHANGE_PASSWORD';
  user: User;
}
export interface ReceiveChangePasswordUserAction {
  type: 'RECEIVE_CHANGE_PASSWORD';
  user: User;
}
export interface FailChangePasswordUserAction {
  type: 'FAIL_CHANGE_PASSWORD';
  Error: ErrorType;
}

type KnownAction =
  | AuthenticateAction
  | AuthenticatedAction
  | RenewTokenAction
  | setNewTokenAction
  | failAuthenticationAction
  | RequestUsersAction
  | ReceiveUsersAction
  | failGettingUsersAction
  | RequestAddUserAction
  | ReceiveAddedUserAction
  | FailAddUserAction
  | RequestSaveUserAction
  | ReceiveSavedUserAction
  | FailSaveUserAction
  | LoginOutAction
  | RequestChangePasswordUserAction
  | ReceiveChangePasswordUserAction
  | FailChangePasswordUserAction;

export const actionCreators = {
  authenticateVerification:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      let authState: AuthenticateState | undefined = getState().authentication;
      if (authState) {
        const token = authState.authenticatedUser?.jwToken;
        if (isTokenValid(token)) {
          localStorage.setItem(
            'AuthenticationState',
            JSON.stringify(authState)
          );
          dispatch({
            type: 'AUTHENTICATED',
            AuthenticatedUser: authState.authenticatedUser!
          });
        }
      }
    },
  authenticate:
    (user: string, password: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      AuthServices.AuthenticateUser(user, password)
        .then((user: AuthenticatedUser) => {
          dispatch({
            type: 'AUTHENTICATED',
            AuthenticatedUser: user
          });
          localStorage.setItem('AuthenticationState', JSON.stringify(user));
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'FAIL_AUTHENTICATION',
            Error: error
          })
        );
    },
  RenewToken: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    if (
      appState.authentication?.isAuthenticate &&
      appState.authentication.authenticatedUser &&
      appState.authentication.authenticatedUser.jwToken
    ) {
      dispatch({
        type: 'RENEW_TOKEN',
        Token: appState.authentication.authenticatedUser.jwToken
      });
      AuthServices.RenewToken(appState.authentication.authenticatedUser.jwToken)
        .then((user: AuthenticatedUser) => {
          dispatch({
            type: 'SET_NEW_TOKEN',
            newToken: user
          });
          localStorage.setItem(
            'AuthenticationState',
            JSON.stringify({
              isLoading: false,
              isAuthenticate: false,
              authenticatedUser: user,
              failOnAuthenticate: false,
              error: undefined
            })
          );
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'FAIL_AUTHENTICATION',
            Error: error
          })
        );
    }
  },
  GetUsers: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    if (
      appState.authentication?.isAuthenticate &&
      appState.authentication.authenticatedUser &&
      appState.authentication.authenticatedUser.jwToken
    ) {
      dispatch({
        type: 'REQUEST_USERS_ACTION'
      });
      AuthServices.GetUsers(appState.authentication.authenticatedUser.jwToken)
        .then((users: User[]) => {
          dispatch({
            type: 'RECEIVE_USERS_ACTION',
            users: users
          });
        })
        .catch((error: ErrorType) =>
          dispatch({
            type: 'FAIL_GETTING_USERS_ACTION',
            Error: error
          })
        );
    }
  },
  AddUser:
    (user: NewUser): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (
        appState.authentication?.isAuthenticate &&
        appState.authentication.authenticatedUser &&
        appState.authentication.authenticatedUser.jwToken
      ) {
        dispatch({
          type: 'REQUEST_ADD_USER',
          newUser: user
        });
        AuthServices.RegisterUser(
          user,
          appState.authentication.authenticatedUser.jwToken
        )
          .then((status: boolean) => {
            dispatch({
              type: 'RECEIVE_ADDED_USER',
              user: user as User
            });
          })
          .catch((error: ErrorType) =>
            dispatch({
              type: 'FAIL_ADD_USER',
              Error: error
            })
          );
      }
    },
  SaveUser:
    (user: User): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (
        appState.authentication?.isAuthenticate &&
        appState.authentication.authenticatedUser &&
        appState.authentication.authenticatedUser.jwToken
      ) {
        dispatch({
          type: 'REQUEST_SAVE_USER',
          user: user
        });
        AuthServices.UpdateUserInfo(
          user,
          appState.authentication.authenticatedUser.jwToken
        )
          .then((user: User) => {
            dispatch({
              type: 'RECEIVE_SAVED_USER',
              user: user as User
            });
          })
          .catch((error: ErrorType) =>
            dispatch({
              type: 'FAIL_SAVE_USER',
              Error: error
            })
          );
      }
    },
  ChangePassword:
    (user: User, password: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (
        appState.authentication?.isAuthenticate &&
        appState.authentication.authenticatedUser &&
        appState.authentication.authenticatedUser.jwToken
      ) {
        dispatch({
          type: 'REQUEST_CHANGE_PASSWORD',
          user: user
        });
        AuthServices.ChangeUserPassWord(
          user,
          password,
          appState.authentication.authenticatedUser.jwToken
        )
          .then((status: boolean) => {
            dispatch({
              type: 'RECEIVE_CHANGE_PASSWORD',
              user: user as User
            });
          })
          .catch((error: ErrorType) =>
            dispatch({
              type: 'FAIL_CHANGE_PASSWORD',
              Error: error
            })
          );
      }
    },
  logout: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    localStorage.removeItem('AuthenticationState');
    dispatch({
      type: 'LOGIN_OUT_ACTION'
    });
  }
};

export interface AuthenticateState {
  // Authentication
  isLoading: boolean;
  isAuthenticate: boolean;
  authenticatedUser: AuthenticatedUser | undefined;
  failOnAuthenticate: boolean;

  // users
  isGettingUsers: boolean;
  gettingUsersSuccess: boolean | undefined;
  failOnGettingUsers: boolean;
  users: User[];
  selectedUser: User | undefined;

  isSaving: boolean;
  isSavingSuccess: boolean | undefined;
  isAdding: boolean;
  isAddingSuccess: boolean | undefined;
  FailAdding: boolean;
  FailSaving: boolean;

  // change password

  isChangingPassword: boolean;
  ChangedPasswordSuccess: boolean | undefined;
  ChangePasswordFail: boolean;

  // common
  error: ErrorType | undefined;
}

const isTokenValid = (currentToken: string | undefined): boolean => {
  if (currentToken) {
    var decodedToken = jwt.decode(currentToken, { complete: true });
    var dateNow = new Date();

    if (
      new Date(decodedToken.payload.exp * 1000).getTime() > dateNow.getTime()
    ) {
      return true;
    }
  }
  return false;
};

const LoadStateFromStore = (): AuthenticateState | null => {
  const json = localStorage.getItem('AuthenticationState');
  console.log(json);
  if (json) {
    let CurrentState: AuthenticateState = unloadedState;
    CurrentState.authenticatedUser = JSON.parse(json);
    const token = CurrentState.authenticatedUser?.jwToken;
    if (isTokenValid(token)) {
      CurrentState.isAuthenticate = true;
      return CurrentState;
    }
  }
  return null;
};

const unloadedState: AuthenticateState = {
  // Authenticate
  isLoading: false,
  isAuthenticate: false,
  authenticatedUser: undefined,
  failOnAuthenticate: false,

  // users
  isGettingUsers: false,
  gettingUsersSuccess: undefined,
  failOnGettingUsers: false,
  users: [],
  selectedUser: undefined,

  isSaving: false,
  isSavingSuccess: undefined,
  isAdding: false,
  isAddingSuccess: undefined,
  FailAdding: false,
  FailSaving: false,

  isChangingPassword: false,
  ChangedPasswordSuccess: undefined,
  ChangePasswordFail: false,

  // common
  error: undefined
};

export const reducer: Reducer<AuthenticateState> = (
  state: AuthenticateState | undefined,
  incomingAction: Action
): AuthenticateState => {
  if (state === undefined) {
    const authState: AuthenticateState | null = LoadStateFromStore();
    return authState ? authState : unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'AUTHENTICATE':
      return {
        ...state,
        isAuthenticate: false,
        authenticatedUser: undefined,
        isLoading: true,
        failOnAuthenticate: false,
        error: undefined
      };
    case 'AUTHENTICATED':
      return {
        ...state,
        isAuthenticate: true,
        authenticatedUser: action.AuthenticatedUser,
        isLoading: false,
        failOnAuthenticate: false,
        error: undefined
      };
    case 'FAIL_AUTHENTICATION':
      return {
        ...state,
        isAuthenticate: false,
        authenticatedUser: undefined,
        isLoading: false,
        failOnAuthenticate: true,
        error: action.Error
      };
    case 'LOGIN_OUT_ACTION':
      return {
        ...state,
        isAuthenticate: false,
        authenticatedUser: undefined,
        isLoading: true,
        failOnAuthenticate: false,
        error: undefined
      };
    case 'SET_NEW_TOKEN':
      return {
        ...state,
        isAuthenticate: true,
        authenticatedUser: action.newToken,
        isLoading: false,
        failOnAuthenticate: false,
        error: undefined
      };
    case 'REQUEST_USERS_ACTION':
      return {
        ...state,
        isGettingUsers: true,
        gettingUsersSuccess: undefined,
        failOnGettingUsers: false,
        error: undefined
      };
    case 'RECEIVE_USERS_ACTION':
      return {
        ...state,
        isGettingUsers: false,
        gettingUsersSuccess: true,
        failOnGettingUsers: false,
        error: undefined,
        users: action.users
      };
    case 'FAIL_GETTING_USERS_ACTION':
      return {
        ...state,
        isGettingUsers: false,
        gettingUsersSuccess: false,
        failOnGettingUsers: true,
        error: action.Error
      };
    case 'REQUEST_ADD_USER':
      return {
        ...state,
        isAdding: true,
        isAddingSuccess: undefined,
        FailAdding: false,
        error: undefined
      };
    case 'RECEIVE_ADDED_USER':
      return {
        ...state,
        isAdding: false,
        isAddingSuccess: true,
        FailAdding: false,
        error: undefined,
        users: [...state.users, action.user].sort(
          Functions.DynamicSort('lastName')
        ),
        selectedUser: action.user
      };
    case 'FAIL_ADD_USER':
      return {
        ...state,
        isAdding: false,
        isAddingSuccess: false,
        FailAdding: true,
        error: action.Error
      };
    case 'REQUEST_SAVE_USER':
      return {
        ...state,
        isSaving: true,
        isSavingSuccess: undefined,
        FailSaving: false,
        error: undefined
      };
    case 'RECEIVE_SAVED_USER':
      return {
        ...state,
        isSaving: false,
        isSavingSuccess: true,
        FailSaving: false,
        error: undefined,
        users: [
          ...state.users.filter((user: User) => user.id !== action.user.id),
          action.user
        ].sort(Functions.DynamicSort('lastName')),
        selectedUser: action.user
      };
    case 'FAIL_SAVE_USER':
      return {
        ...state,
        isSaving: false,
        isSavingSuccess: false,
        FailSaving: true,
        error: action.Error
      };
    case 'REQUEST_CHANGE_PASSWORD':
      return {
        ...state,
        isChangingPassword: true,
        ChangedPasswordSuccess: undefined,
        ChangePasswordFail: false,
        error: undefined
      };
    case 'RECEIVE_CHANGE_PASSWORD':
      return {
        ...state,
        isChangingPassword: false,
        ChangedPasswordSuccess: true,
        ChangePasswordFail: false,
        error: undefined
      };
    case 'FAIL_CHANGE_PASSWORD':
      return {
        ...state,
        isChangingPassword: false,
        ChangedPasswordSuccess: false,
        ChangePasswordFail: true,
        error: action.Error
      };
  }

  return state;
};
