import * as React from 'react';
import { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as ProductosStore from '../stores/Productos';
import * as MateriaPrimaStore from '../stores/Materia_Prima';
import * as OrdenFabricacionStore from '../stores/OrdenFabricacion';
import { useHistory, useParams } from 'react-router-dom';
import { ApplicationState } from '../stores';
import { OrdenForm } from '../components/orden/ordenForm';
import { ProductoFormOrden } from '../components/orden/productoFormOrden';
import { FaArrowAltCircleLeft } from 'react-icons/fa';

export const NewOrdenFabricacionPage = () => {
  const { id }: { id: string } = useParams();
  const [enabledCreateProduct, setEnabledCreateProducto] =
    React.useState(false);
  const [option, setOption] = React.useState(enabledCreateProduct ? -1 : 2);
  let history = useHistory();
  const dispatch = useDispatch();
  const productos = useSelector((state: ApplicationState) => state.productos);

  const SuppliesState = useSelector(
    (state: ApplicationState) => state.Supplies
  );
  const OrdenesState = useSelector((state: ApplicationState) => state.ordenes);

  useEffect(() => {
    dispatch(mapDispatchToProps.cleanNewOrder());
    dispatch(mapDispatchToProps.setProducto(undefined));
  }, []);

  useEffect(() => {
    dispatch(mapDispatchToProps.getAllProductos('0', '5000'));
    dispatch(mapDispatchToProps.GetAllSupplies(0, 5000));
  }, []);

  const CreateNewOrderHandler = () => {};
  const ViewCreatedOrderHandler = () => {
    history.push('/ordenes/info/' + OrdenesState?.newOrderGenerated?.id);
  };

  return !OrdenesState?.newOrderGenerated ? (
    <>
      <div className="pageHeader">
        <h2>Nueva orden de fabricación</h2>
        <button
          type="button"
          title="Volver"
          className="btn btn-primary mt-3 mb-2 me-3 float-end"
          onClick={() => {
            history.goBack();
          }}
        >
          <FaArrowAltCircleLeft />
        </button>
      </div>
      {enabledCreateProduct && (
        <>
          <label className="SubContentTitle">
            1 - Que producto desea fabricar
          </label>
          <div className="ProductButtons">
            <button
              type="button"
              className={
                option === 1
                  ? 'btn btn-secondary selected'
                  : 'btn btn-secondary'
              }
              onClick={() => {
                setOption(1);
                dispatch({
                  type: 'SET_PRODUCTO',
                  producto: { formula: { lista_elementos: [] } }
                });
              }}
            >
              Fabricar un nuevo producto
            </button>
            <button
              type="button"
              className={
                option === 2
                  ? 'btn btn-secondary selected'
                  : 'btn btn-secondary'
              }
              onClick={() => {
                setOption(2);
              }}
            >
              Fabricar un producto existente
            </button>
          </div>
        </>
      )}
      <>
        {option === 1 ? (
          <>
            <label className="SubContentTitle">
              2 - Ingrese los datos del producto
            </label>
            <ProductoFormOrden
              materiaPrimas={SuppliesState?.Supplies}
              onAddedOrder={CreateNewOrderHandler}
            />
          </>
        ) : null}
      </>

      <>
        {option === 2 ? (
          <>
            <label className="SubContentTitle">
              {enabledCreateProduct ? '2' : '1'} - Seleccione el producto a
              fabricar
            </label>
            <div className="formContainer">
              <OrdenForm
                productos={productos?.productos}
                materiaPrimas={SuppliesState?.Supplies}
                onAddedOrder={CreateNewOrderHandler}
              />
            </div>
          </>
        ) : null}
      </>
    </>
  ) : (
    <>
      <div className="pageHeader">
        <h2>Nueva orden de fabricación</h2>
        <button
          type="button"
          title="Volver"
          className="btn btn-primary mt-3 mb-2 me-3 float-end"
          onClick={() => {
            history.goBack();
          }}
        >
          <FaArrowAltCircleLeft />
        </button>
      </div>
      <div className="divCenter">
        <label>Se ha creado la orden de fabricacion</label>
        <button className="btn btn-primary" onClick={ViewCreatedOrderHandler}>
          Ver detalle de Orden de fabricación
        </button>
        <hr />
        <a
          className="btn btn-primary"
          href={
            process.env.REACT_APP_API_ENDPOINT +
            'v1/ordenesfabricaciones/' +
            OrdenesState?.newOrderGenerated?.id +
            '/report'
          }
          target="_blank"
          rel="noreferrer"
        >
          Imprimir Orden de fabricación
        </a>
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.ordenes
});

const mapDispatchToProps = {
  ...OrdenFabricacionStore.actionCreators,
  ...ProductosStore.actionCreators,
  ...MateriaPrimaStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewOrdenFabricacionPage as any);
