import React from 'react';
//import styled from 'styled-components'
import { useTable, usePagination, useRowSelect } from 'react-table';
import { Badge } from 'react-bootstrap';
import moment from 'moment';
import './paginationTable.css';
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

function PaginationTable({ columns, data, hiddenColumns = [], buttons = [] }) {
  // console.log(editableFuncion);
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, hiddenColumns, pageSize: 5 }
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        ...columns,
        buttons && buttons.length > 0
          ? {
              Header: 'Acciones'
            }
          : {},

        buttons
          ? {
              id: 'selection',
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllPageRowsSelectedProps }) => (
                <div>
                  {/* <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} /> */}
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <div>
                  {/* <button
                className="btn btn-secondary me-1"
                onClick={() => {
                  editableFuncion(row.values);
                }}
              >
                {' '}
                Editar
              </button> */}
                  <>
                    {buttons.map((b, index) => (
                      <button
                        onClick={() => b.btnFunc(row.values)}
                        className={b.btnclass}
                        key={index}
                      >
                        {b.btnText}
                      </button>
                    ))}
                  </>
                  {/* <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} /> */}
                </div>
              )
            }
          : null
      ]);
    }
  );

  // Render the UI for your table
  return (
    <>
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="table-datos" {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="col-md-12 pagination float-end">
        {/* <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"Principio"}
        </button>{" "} */}
        <button
          className="btn btn-primary"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {'<'}
        </button>{' '}
        <button
          className="btn btn-primary"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {'>'}
        </button>{' '}
        {/* <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {"Final"}
        </button>{" "} */}
        <span className="ml-2 mt-1 pr-2">
          Página{' '}
          <strong>
            {pageIndex + 1} de {pageOptions.length}
          </strong>{' '}
        </span>
        {/* <span>
          | ir a pagina:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>  */}
      </div>
    </>
  );
}

function PaginationTableFinalizar({
  columns,
  data,
  hiddenColumns = [],
  buttons = []
}) {
  // console.log(editableFuncion);
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, hiddenColumns, pageSize: 5 }
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        ...columns,
        buttons && buttons.length > 0
          ? {
              Header: 'Acciones'
            }
          : {},

        buttons
          ? {
              id: 'selection',
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllPageRowsSelectedProps }) => (
                <div>
                  {/* <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} /> */}
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <div>
                  {/* <button
                className="btn btn-secondary me-1"
                onClick={() => {
                  editableFuncion(row.values);
                }}
              >
                {" "}
                Editar
              </button> */}
                  <>
                    {buttons.map((b, index) =>
                      !row.values.finalizado ? (
                        <button
                          onClick={() => b.btnFunc(row.values)}
                          className={b.btnclass}
                          key={index}
                        >
                          {b.btnText}
                          {/* {row.values.cantidad} */}
                        </button>
                      ) : row.values.aprobado ? (
                        <>
                          <h6>
                            <Badge bg="success">Verificado</Badge>
                          </h6>
                        </>
                      ) : (
                        <h6>
                          <Badge bg="warning">
                            Enviado a stock de materia prima
                          </Badge>
                        </h6>
                      )
                    )}
                  </>
                  {row.values.finalizado && (
                    <small>
                      {moment(Date(row.values.fechafinalizado)).add(-3, 'h').format('DD/MM/YYYY HH:mm')}
                    </small>
                  )}
                  {/* <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} /> */}
                </div>
              )
            }
          : null
      ]);
    }
  );

  // Render the UI for your table
  return (
    <>
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="table-datos" {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="col-md-12 pagination float-end">
        {/* <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"Principio"}
        </button>{" "} */}
        <button
          className="btn btn-primary"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {'<'}
        </button>{' '}
        <button
          className="btn btn-primary"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {'>'}
        </button>{' '}
        {/* <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {"Final"}
        </button>{" "} */}
        <span className="ml-2 mt-1 pr-2">
          Página{' '}
          <strong>
            {pageIndex + 1} de {pageOptions.length}
          </strong>{' '}
        </span>
        {/* <span>
          | ir a pagina:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>  */}
      </div>
    </>
  );
}

function SimpleTable({ columns, data, hiddenColumns = [], buttons = [] }) {
  // Use the state and functions returned from useTable to build your UI

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: { hiddenColumns }
      },
      (hooks) => {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          ...columns,
          {
            Header: 'Acciones'
          },
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div>
                {/* <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} /> */}
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                {/* <button
                  className="btn btn-secondary me-1"
                  onClick={() => {
                    editableFuncion(row.values);
                  }}
                >
                  {' '}
                  Editar
                </button> */}
                <>
                  {buttons.map((b, index) => (
                    <button
                      onClick={() => b.btnFunc(row.values)}
                      className={b.btnclass}
                      key={index}
                    >
                      {b.btnText}
                    </button>
                  ))}
                </>
                {/* <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} /> */}
              </div>
            )
          }
        ]);
      }
    );

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                if (!row.values.pausa) {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                } else {
                  if (
                    (cell.column.Header &&
                      cell.column.Header === 'Materia Prima') ||
                    (cell.column.Header && cell.column.Header === 'Cantidad')
                  )
                    return <td {...cell.getCellProps()}> PAUSA </td>;
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                }
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
function PTable(meta) {
  const columns = meta.columnas;
  const data = meta.datos;
  const hiddenColumns = meta.hiddenColumns;
  return (
    <PaginationTable
      columns={columns}
      data={data}
      hiddenColumns={hiddenColumns}
      buttons={meta.buttons}
    />
  );
}
function POrdenTable(meta) {
  const columns = meta.columnas;
  const data = meta.datos;
  const hiddenColumns = meta.hiddenColumns;
  return (
    <PaginationTableFinalizar
      columns={columns}
      data={data}
      hiddenColumns={hiddenColumns}
      buttons={meta.buttons}
    />
  );
}
function Table(meta) {
  const columns = meta.columnas;
  const data = meta.datos;
  const hiddenColumns = meta.hiddenColumns;
  return (
    <SimpleTable
      columns={columns}
      data={data}
      hiddenColumns={hiddenColumns}
      buttons={meta.buttons}
    />
  );
}
//export  PTable;
//export default Table;
export { PTable, Table, POrdenTable };
