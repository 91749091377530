import { Route, Redirect } from 'react-router-dom';

import { useAuth } from "react-oidc-context";

export const AuthorizeRoute = (props: any) => {

  const auth = useAuth();

  const { component: Component, ...rest } = props;
  var link = document.createElement('a');
  link.href = props.path;
  const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
  const redirectUrl = `/${process.env.REACT_APP_LOGINPAGE}?returnUrl=${encodeURI(window.location.href)}`;

  

  return !auth.isAuthenticated ?
    <></>
    : (
      <Route
        {...rest}
        render={(props) => {
          if (auth.isAuthenticated) {
            return <Component {...rest} {...props} />;
          } else {
            auth.signinRedirect();
            return <></>
          }
        }}
      />
    );
};

export default AuthorizeRoute;
