import * as React from 'react';
import LowStockWidgetComponent from '../components/materia_prima/widget/LowStockWidgetComponent';
import { OdenFabricacionEnCursoList } from '../components/orden/widgets/OrdenFabricacionEnCursoList/OrdenFabricacionEnCursoListComponent';

export const HomePage = () => {
  return (
    <>
      <div className="pageHeader">
        <h2>Panel de información</h2>
      </div>
      <div className="widgetRow">
        <OdenFabricacionEnCursoList isColor={false}/>
        <OdenFabricacionEnCursoList isColor={true}/>
        <LowStockWidgetComponent />
      </div>
    </>
  );
};
