import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import MaterialPrimaPicker from '../../../Pickers/MateriaPrimaPicker/MateriaPrimaPicker.Component';
import { MateriaPrima } from '../../../model/materia_prima';
import moment from 'moment';

//style
import './RemitoForm.Styles.css';

// funtcion
import * as ComponentFunctions from './Remitoform.Functions';


interface FormValidation {
    success: boolean;
    errors: string[];
  }

export const RemitoFormComponent = (props: ComponentFunctions.Props) => {
    
    const [formSubmitted, setFormSubmitted] = useState<boolean>();
    const [FormValidationStatus, setFormValidationStatus] = useState<
        FormValidation | undefined
    >();

    // efects
    useEffect(() => {
        props.RemitoId && props.Get(props.RemitoId!);
    }, [props.RemitoId !== undefined]);

    useEffect(() => {
        if (formSubmitted && (props.CreateSuccessfully || props.UpdateSuccessfully)) {
            ResetForm();
            props.onSaveRemito(props.remito);
        }
    }, [props.CreateSuccessfully === true, props.UpdateSuccessfully === true]);

    useEffect(() => {
        setMateriaPrimaId(props.MateriaPrimaId);
    }, [props.MateriaPrimaId !== undefined]);

    // load previous value data
    useEffect(() => {
        if (props.remito) {
            setProveedor(props.remito.proveedor);
            setCodigo(props.remito.codigo);
            setFechaRemito(props.remito.fechaRemito);
            setMateriaPrimaId(props.remito.materiaPrima?.id);
            setCantidad(props.remito.cantidad);
            setObservaciones(props.remito.observaciones);
            setPrecioUnitario(props.remito.precioUnitario);
            setPrecioTotal(props.remito.precioUnitario * props.remito.cantidad);
        }
    }, [props.remito !== undefined]);

    /*
    proveedor: string;
    codigo: string;
    fechaRemito: Date;
    materiaPrimaId: number;
    cantidad: number;
    observaciones: string;
    precioUnitario: number;
    precioTotal: number;
    */

    // form values
    const [proveedor, setProveedor] = useState<string>();
    const [codigo, setCodigo] = useState<string>();
    const [fechaRemito, setFechaRemito] = useState<Date>(new Date());
    const [materiaPrimaId, setMateriaPrimaId] = useState<number>();
    const [cantidad, setCantidad] = useState<number>();
    const [observaciones, setObservaciones] = useState<string>();
    const [precioUnitario, setPrecioUnitario] = useState<number>();
    const [precioTotal, setPrecioTotal] = useState<number>();


    // form field handlers
    const ProveedorFomFieldChangeHandler = (event: any) => {
        setProveedor(event.target.value);
    };
    const CodigoFomFieldChangeHandler = (event: any) => {
        setCodigo(event.target.value);
    };
    const FechaRemitoFomFieldChangeHandler = (event: any) => {
        setFechaRemito(event.target.value);
    };
    const MateriaPrimaIdFomFieldChangeHandler = (event: any) => {
        setMateriaPrimaId(event.target.value);
    };
    const CantidadFomFieldChangeHandler = (event: any) => {
        setCantidad(event.target.value);
    };
    const ObservacionesFomFieldChangeHandler = (event: any) => {
        setObservaciones(event.target.value);
    };
    const PrecioUnitarioFomFieldChangeHandler = (event: any) => {
        setPrecioUnitario(event.target.value);
        setPrecioTotal(cantidad ? (event.target.value * cantidad!) : 0);
    };
    const PrecioTotalFomFieldChangeHandler = (event: any) => {
        setPrecioTotal(event.target.value);
    };

    // form validate
    const ValidateFormData = (): boolean => {
        var validationResult: boolean = true;

    var FormValidation: FormValidation = { success: true, errors: [] };
    setFormValidationStatus(FormValidation);
        
        if (proveedor === undefined) {
            validationResult = false;
            FormValidation.errors.push('Debe informar un proveedor');
        };

        if (codigo === undefined) {
            validationResult = false;
            FormValidation.errors.push('Debe informar un número de remito');
        };

        if (fechaRemito === undefined) {
            validationResult = false;
            FormValidation.errors.push('Debe informar la fecha de emisión del remito');
        };

        if (materiaPrimaId === undefined) {
            validationResult = false;
            FormValidation.errors.push('Debe seleccionar una materia prima');
        };

        if (cantidad === undefined) {
            validationResult = false;
            FormValidation.errors.push('Debe informar la cantidad de materia prima');
        };

        if (precioUnitario === undefined) {
            validationResult = false;
            FormValidation.errors.push('Debe informar un precio unitario');
        };

        FormValidation.success = validationResult;
        setFormValidationStatus(FormValidation);
        return validationResult;
    };


    // actions
    const ResetForm = () => {
        setProveedor(undefined);
        setCodigo(undefined);
        setFechaRemito(new Date());
        setMateriaPrimaId(undefined);
        setCantidad(undefined);
        setObservaciones(undefined);
        setPrecioUnitario(undefined);
        setPrecioTotal(undefined);
    };

    const Save = () => {
        setFormSubmitted(true);
        if (ValidateFormData()) {
            if(props.RemitoId) {
                props.Update({
                    id: props.RemitoId,
                    proveedor: proveedor!,
                    codigo: codigo!,
                    fechaRemito: fechaRemito!,
                    materiaPrimaId: materiaPrimaId!,
                    cantidad: cantidad!,
                    observaciones: observaciones!,
                    precioUnitario: precioUnitario!,
                    precioTotal: precioTotal!
                });
            } else {
                props.Create({
                    proveedor: proveedor!,
                    codigo: codigo!,
                    fechaRemito: fechaRemito!,
                    materiaPrimaId: materiaPrimaId!,
                    cantidad: cantidad!,
                    observaciones: observaciones!,
                    precioUnitario: precioUnitario!,
                    precioTotal: precioTotal!
                });
            }
        }
    };

    const Cancel = () => {
        ResetForm();
        props.onCancel();
    };
    
    
    // form content
    
    const Loading = props.isLoading && (
        <div className="LoadingContainer">
            <label>Cargando...</label>
        </div>
    );

    const Form = (
        <form className="contentpage needs-validation" noValidate>
            {FormValidationStatus?.success === false && 
            <div className="alert alert-danger" role="alert">
                <ul>
                {FormValidationStatus?.errors?.map((error: string) => (
                    <li>{error}</li>
                ))}
                </ul>
            </div>
            }
            <div className="form-group">
                <label className="form-label">Número de remito</label>
                <input
                    className="form-control"
                    type="text"
                    value={codigo}
                    onChange={CodigoFomFieldChangeHandler}
                />
            </div>
            <div className="form-group">
                <label className="form-label">Proveedor</label>
                <input
                    className="form-control"
                    type="text"
                    value={proveedor}
                    onChange={ProveedorFomFieldChangeHandler}
                />
            </div>
            <div className="form-group">
                <label htmlFor="remitoDate" className="form-label">Fecha remito</label>
                <input
                    className="form-control"
                    id="remitoDate"
                    type="date"
                    placeholder="dd/MM/yyyy"
                    value={moment(fechaRemito).format('YYYY-MM-DD')}
                    onChange={FechaRemitoFomFieldChangeHandler}
                />
            </div>
            <div className="form-group">
                <label className="form-label">Materia prima</label>
                <MaterialPrimaPicker SelectedSupplyId={materiaPrimaId} OnSelectedSupplyChanged={(selectedSupply: MateriaPrima | undefined) => setMateriaPrimaId(selectedSupply?.id)} />
            </div>
            <div className="form-group">
                <label htmlFor='remitoCantidad' className="form-label">Cantidad</label>
                <div className="input-group has-validation">
                    <input
                        className="form-control"
                        type="number"
                        step="1"
                        required
                        id="remitoCantidad"
                        max={10000000}
                        maxLength={8}
                        value={cantidad}
                        onChange={CantidadFomFieldChangeHandler}
                    />
                    <span className="input-group-text">Kg</span>
                </div>
            </div>
            <div className="form-group">
                <label className="form-label">Precio unitario</label>
                <div className="input-group mb-3">
                <input
                    className="form-control"
                    type="number"
                    step="0.01"
                    value={precioUnitario}
                    onChange={PrecioUnitarioFomFieldChangeHandler}
                />
                <span className="input-group-text">u$d</span>
                </div>
                
            </div>
            <div className="form-group">
                <label className="form-label">Precio total</label>
                <div className="input-group mb-3">
                <input
                    className="form-control"
                    type="text"
                    disabled={true}
                    value={precioTotal}
                    onChange={PrecioTotalFomFieldChangeHandler}
                />
                <span className="input-group-text">u$d</span>
                </div>
                
            </div>
            <hr/>
            <div className="form-group">
                <label className="form-label">Observaciones</label>
                <textarea
                    className="form-control"
                    value={observaciones}
                    onChange={ObservacionesFomFieldChangeHandler}
                />
            </div>
        </form>
    );

    const SaveButton = (
        <button
            className="btn btn-primary"
            disabled={props.isCreating || props.isUpdating}
            onClick={() => {
                Save();
            }}
        >
            { (props.isCreating || props.isUpdating) ? 'Guardando...' : 'Guardar'}
        </button>
    );

    const CancelButton = (
        <button
            className="btn btn-secondary"
            onClick={() => {
                Cancel();
            }}
        >
            Cancelar
        </button>
    );

    const FormButtons = (
        <div className="formButtons">
            {SaveButton}
            {CancelButton}
        </div>
    );

    const FormContainer = (
        <div className="formContainer">
            {Form}
            {FormButtons}
        </div>
    );

    const FormContent = (
        <>
            {formSubmitted && props.error && <div className='RemitoError'>
                <ul>
                    {props.error.Errors?.map((error: string) => <li>{error}</li>)}
                </ul>
            </div>}
            {Loading}
            {FormContainer}
        </>
    );

    return (
        <div className="RemitoFormComponent">
            {FormContent}
        </div>
    );
    
}

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(RemitoFormComponent as any);